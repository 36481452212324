<div class="container-access-control-directory">

  <!-- CONTAINER FILTERS -->
  <div class="d-flex">
    <div class="col-lg-4 col-md-4">
      <app-data-picker-default
        [dates]="getRangeDate()"
        (event)="onChange($event)"
      >
      </app-data-picker-default>
    </div>

    <div class="col-lg-2 col-md-2">
      <app-select-default
        key="operations"
        title="Operações"
        [dropdownList]="this.data.value.filters.operations"
        [selectedItems]="['login']"
        [itemsShowLimit]="2"
        (event)="onChange($event)"
      >
      </app-select-default>
    </div>    

    <div *ngIf="isAdmin()" class="col-lg-2 col-md-2">
      <app-select-default
        key="companies"
        title="Empresas"
        [dropdownList]="this.data.value.filters.companies"
        [itemsShowLimit]="2"
        (event)="onChange($event)"
      >
      </app-select-default>
    </div>
  </div>

  <div class="container">
    <div>
      <div class="card-chart chart-full col-12 col-md-12 col-xxl-12 chart-height">
        <div class="card-chart-header chart-full-header">
          <p class="chart-title">Utilização por Diretoria (%)</p>
        </div>
        <hr>
    
        <!-- Verifica se está carregando -->
        <div *ngIf="loading" class="loading-container d-flex flex-column justify-content-center align-items-center expand-max">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Carregando...</span>
          </div>
          <p class="loading-text mt-3">Estamos carregando os dados. Por favor, aguarde.</p>
        </div>
  
        <!-- Verifica se houve erro na requisição -->
        <div *ngIf="error && !loading" class="error-container d-flex flex-column justify-content-center align-items-center expand-max">
          <fa-icon [icon]="icons.danger" class="text-danger" size="3x"></fa-icon>
          <p class="error-text mt-3">Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.</p>
        </div>
  
        <div *ngIf="!loading && !error" class="expand-max">
          <div class="legend-container">
            <span class="legend-dot" [ngStyle]="{'background-color': colors[0] }"></span>
            <span class="legend-text">Percentual de acesso</span>
          </div>

          <span class="days-difference">
            Total de Dias Selecionados: <strong>{{ this.daysBetween }}</strong>
          </span>
          
          <div *ngIf="!loading && !error" class="expand-max">  
            <div style="height: 85%" [ngStyle]="isLessThan100  ? {'display': 'flex', 'align-items': 'center', 'justify-content': 'center'} : {}">
              <div class="d-flex chart-container" [ngStyle]="{ 'width': barWidthProportion }">
                <canvas
                  baseChart
                  id="myChart"
                  height="100"
                  width="80"
                  [datasets]="barChartData.datasets"
                  [labels]="barChartData.labels"
                  [options]="options"
                  [legend]="false"
                  [chartType]="'bar'"
                >
                </canvas>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  
    <!-- Tabela de Informações com Estilos Aplicados -->
    <div>
      <table class="image-verification-table">
        <thead>
          <tr class="image-verification-header">
            <th>Informações de Acesso</th>
            <th *ngFor="let label of barChartLabelTreat()">{{ label }}</th>
          </tr>
        </thead>
        
        <tbody>
          <!-- Linha para Quantidade de Acessos -->
          <tr class="image-verification-row">
            <td class="image-verification-body" [matTooltip]="'Número total de acessos registrados para a diretoria no período selecionado.'">
              Quantidade de acessos
            </td>
            <td 
              *ngFor="let data of this.data.value.data.datasets"
              class="image-verification-body"
              [matTooltip]="'Acesso registrado para esta diretoria.'"
            >
              {{ data[0] }}
            </td>
          </tr>
        
          <!-- Linha para Índice de Frequência	 -->
          <tr class="image-verification-row">
            <td 
              class="image-verification-body" 
              [matTooltip]="'O Índice de Frequência mede a dispersão dos acessos realizados por usuários de uma diretoria em relação à data média do período selecionado'"
              matTooltipClass="custom-tooltip"
            >
              Índice de Frequência	
            </td>
            <td 
              *ngFor="let data of this.data.value.data.datasets"
              class="image-verification-body"
              [matTooltip]="'O Índice de Frequência mede a dispersão dos acessos realizados por usuários de uma diretoria em relação à data média do período selecionado'"
              matTooltipClass="custom-tooltip"
            >
              {{ data[2]?.toFixed(2) || 0 }}
            </td>
          </tr>            
        
          <!-- Linha para  Quantidade de Dias Acessados -->
          <tr class="image-verification-row">
            <td class="image-verification-body" [matTooltip]="'Número de dias úteis em que a diretoria teve acessos no período.'">
              Quantidade de Dias Acessados
            </td>
            <td 
              *ngFor="let data of this.data.value.data.datasets"
              class="image-verification-body"
              [matTooltip]="'Quantidade de acessos registrados no período para esta diretoria.'"
            >
              {{ data[3] || 0 }}
            </td>
          </tr>
        
          <!-- Linha para Quantidade de Usuários Ativos -->
          <tr class="image-verification-row">
            <td class="image-verification-body" [matTooltip]="'Número total de usuários que realizaram acessos para a diretoria.'">
              Quantidade de Usuários Ativos
            </td>
            <td 
              *ngFor="let data of this.data.value.data.datasets"
              class="image-verification-body"
              [matTooltip]="'Número de usuários ativos nesta diretoria.'"
            >
              {{ data[4] || 0 }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
