import { subDays } from "date-fns";

const today = new Date();
today.setHours(0, 0, 0, 0);

export class GenerateDate {
  static getDates()  {
    const startDate = subDays(today, 30);
    const endDate = subDays(today, 1);
    return { 
      startDate,
      endDate
    }
  }

  static getYesterday() {
    const yesterday = subDays(today, 1);
    const startDate = subDays(yesterday, 1);
    const endDate = yesterday
    return { 
      startDate,
      endDate
    }
  }

  static getDateMinusDays(days: number) {
    const startDate = subDays(today, days);
    const endDate = today
    return { 
      startDate,
      endDate
    }
  }

  static getLastMonthRange() {
    // Calcula o último dia do mês anterior
    const endDate = new Date(today.getFullYear(), today.getMonth() - 2, 0);

    // Calcula o primeiro dia do mês anterior
    const startDate = new Date(today.getFullYear(), today.getMonth() - 3, 1);

    return { 
      startDate,
      endDate 
    };
  }
}