import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from "rxjs";
import { TicketPopUpComponent } from "src/app/maps/ticket-pop-up/ticket-pop-up.component";
@Component({
  selector: 'app-report-table-modal',
  templateUrl: './report-table-modal.component.html',
  styleUrls: ['./report-table-modal.component.scss']
})
export class ReportTableModalComponent implements OnInit {

  @Input() data: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  @Input() totalPages = new BehaviorSubject<number>(1);
  @Input() handlePageChange!: (results: { page: number, size: number, search: string, isPreview: boolean, sortBy: string[], sortDirection: string[] }) => void // Lida com as alterações pendentes
  @Input() exportData!: ({ search, sortBy, sortDirection }) => void;

  loading: boolean = false;
  error: boolean = false;
  currentPage = new BehaviorSubject<number>(1); // Página atual
  itemsPerPage = 15

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) {}
  
  ngOnInit(): void {}

  openTicketPopup = ({ rowId, value, field }: { rowId: string, field: string, value: any }) => {
    if (field === 'ticketId') {
      const modalRef = this.modalService.open(TicketPopUpComponent, { size: 'lg' });
      modalRef.componentInstance.modalData = { ticketId: value, guid: rowId  };
    }
  }

  dismiss(): void {
    this.activeModal.close();
  }
}