export interface InputFilter {
  startDate?: string;
  endDate?: string;
  causes?: string;
  validations?: string;
  regionals?: string;
  directors?: string;
  states?: string;
  clusters?: string;
  subclusters?: string;
  cities?: string;
  technicianIds?: string;
  companies?: string;
  families?: string;
}

export const defaultFilters = {
  causes: [],
  validations: [],
  regionals: [],
  directors: [],
  states: [],
  clusters: [],
  subclusters: [],
  cities: []
}