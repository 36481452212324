import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environment';
import { DataClassificationService } from 'src/app/services/data-classification.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-data-classification',
  templateUrl: './data-classification.component.html',
  styleUrls: ['./data-classification.component.sass']
})
export class DataClassificationComponent implements OnInit {
  @Input() modalData: { ticketId: number; cause: string; guid: string };

  showHistoric = false;
  historicTickets: any[] = [];
  photos: string[] = [];
  ticketId = 0;
  guid = '';
  currentSlide = 0;
  selectedOption: number | null = null;
  selectedIncidentType: string | null = null;
  isLoading = false;
  selectedImageUrl: string | null = null;
  readonly classificationMap: { [key: string]: string } = {
    'Sabotage': 'Sabotagem',
    'Vandalism': 'Vandalismo',
    'Theft': 'Furto',
    'Inconclusive': 'Inconclusivo'
  };
  readonly slideConfig3 = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    adaptiveHeight: true
  };

  constructor(
    private dataClassificationService: DataClassificationService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    const storedTicketId = localStorage.getItem('currentTicketId');
    this.ticketId = storedTicketId ? Number(storedTicketId) : 0;
    this.ticketId === 0 ? this.fetchNextTicket() : this.getPhotos();
  }

  selectTicket(ticketId: number): void {
    this.ticketId = ticketId;
    localStorage.setItem('currentTicketId', String(ticketId));
    this.getPhotos();
    this.setIncidentTypeBasedOnTicket();
    this.showHistoric = false;
  }

  setIncidentTypeBasedOnTicket(): void {
    this.dataClassificationService.getTicketClassification(this.ticketId).subscribe(
      (details) => this.selectedIncidentType = this.classificationMap[details.classification] || 'Inconclusivo',
      (error) => console.error('Erro ao buscar detalhes do ticket:', error)
    );
  }

  getPhotos(): void {
    this.photos = Array.from({ length: 5 }, (_, i) =>
      `${environment.imagemUrl}/fotosClaro/${this.ticketId}-${i + 1}.jpg`
    );
  }

  fetchNextTicket(): void {
    this.isLoading = true;
    this.dataClassificationService.getNextTicket(this.ticketId).subscribe(
      ({ ticketId, guid }) => {
        this.ticketId = ticketId;
        this.guid = guid;
        localStorage.setItem('currentTicketId', String(ticketId));
        this.getPhotos();
        this.isLoading = false;
      },
      (error) => {
        console.error('Erro ao buscar próximo ticket:', error);
        this.isLoading = false;
      }
    );
  }

  updateTicket(): void {
    const user = this.authService.getCurrentUser();
    if (!user || !this.ticketId || !this.selectedIncidentType) {
      console.error('Erro: dados insuficientes para atualizar o ticket.');
      return;
    }

    const payload = {
      referenceId: this.ticketId,
      userId: user.id,
      classification: Object.keys(this.classificationMap).find(
        (key) => this.classificationMap[key] === this.selectedIncidentType
      ) || 'Inconclusive',
      status: 'filled'
    };

    this.isLoading = true;
    this.dataClassificationService.updateClassification(
      `${environment.baseUrl}/vandalism/image/classifications`,
      payload
    ).subscribe(
      () => {
        console.log('Classificação atualizada com sucesso!');
        this.fetchNextTicket();
      },
      (error) => {
        console.error('Erro ao atualizar classificação:', error);
        this.isLoading = false;
      }
    );
  }

  toggleHistoric(): void {
    this.showHistoric = !this.showHistoric;
    if (this.showHistoric) this.loadHistoricTickets();
  }

  loadHistoricTickets(): void {
    const user = this.authService.getCurrentUser();
    if (!user) {
      console.error('Usuário não autenticado.');
      return;
    }

    this.dataClassificationService.getHistoricTickets(
      `${environment.baseUrl}/vandalism/image/classifications/last-10/${user.id}`
    ).subscribe(
      (tickets) => this.historicTickets = tickets,
      (error) => console.error('Erro ao carregar histórico de tickets:', error)
    );
  }

  onSlideChange(event: any): void {
      this.currentSlide = event.currentSlide;
    }
  
  selectImageFromIndicator(index: number): void {
    this.currentSlide = index;
    this.selectedImageUrl = this.photos[index];
  }

  selectIncidentType(type: string): void {
      this.selectedIncidentType = type; 
    }

  openHistoric(): void {
      this.showHistoric = !this.showHistoric;
      if (this.showHistoric) {
        this.loadHistoricTickets();
      }
    }

  closeHistoric(): void {
    this.showHistoric = false;
  }
}
