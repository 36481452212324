import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environment';

@Injectable({
  providedIn: 'root'
})
export class DataExportService {

  private readonly baseUrl;
  private readonly publisherUrl;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
    this.publisherUrl = environment.publisherUrl;
   }

  getTables(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/vandalism/extractor`);
  }

  getFilters(reportId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/vandalism/extractor/filters?reportId=${reportId}`);
  }

  sendFields(reportId: number, fields: string) {
    const url = `${environment.baseUrl}/vandalism/extractor?reportId=${reportId}&fields=${fields}`;
    return this.http.get(url);
  }

  generateReport(reportId: number, filters: any, page: number, size: number, startDateParam: string, endDateParam: string): Observable<any> {
    const url = `${this.baseUrl}/vandalism/extractor/extract?reportId=${reportId}&page=${page}&size=${size}${startDateParam}${endDateParam}`;
  
    return this.http.post<any>(url, filters);
  }

  generateReportExport(reportId: number, filters: any, columns: string[], startDateParam: string, endDateParam: string, email: string): Observable<any> {
    const url = `${this.publisherUrl}/publisher/extractor/extract?reportId=${reportId}${startDateParam}${endDateParam}&email=${email}`;

    const payload = {
      filters,
      columns
    };

    return this.http.post<any>(url, payload);
  }
}
