import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { TransformStrategy, TransformStrategyResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ExcelProcessorService {

  constructor() {}

  processExcel(strategy: TransformStrategy, file: File): Promise<TransformStrategyResult> {
    return new Promise<TransformStrategyResult>((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        try {
          const data = new Uint8Array(event.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: 'array' });
  
          const sheetName = workbook.SheetNames[0];
          const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
  
          // Normaliza as chaves de cada linha do Excel para maiúsculas
          const normalizedData = sheetData.map((row) =>
            Object.keys(row).reduce((acc, key) => {
              acc[key.toUpperCase().trim()] = row[key];
              return acc;
            }, {} as Record<string, any>)
          );
  
          // Use a estratégia para transformar os dados normalizados
          const result = strategy.transform(normalizedData);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      };
  
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  }
  
}
