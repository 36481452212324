import { Component, OnInit } from '@angular/core';
import { DataExportService } from 'src/app/services/data-export.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';

interface Column {
  name: string;
  fieldRep: string;
}

type Filters = Record<string, { selected: boolean, value: string }[]>

type DynamicFilters = {
  filters: Filters[]
};

@Component({
  selector: 'app-data-export',
  templateUrl: './data-export.component.html',
  styleUrls: ['./data-export.component.sass']
})
export class DataExportComponent implements OnInit {

  startDate: string | null = null;
  endDate: string | null = null;

  currentPage = 0;
  totalPages = 1;
  pageSize = 7;
  pageInput: number = 1;

  currentStep = 1;
  selectedTable: number | null = null;
  selectedColumns: Column[] = [];
  generatedData: string[] = [];
  tables: string[] = [];

  availableColumns: string[] = []; 

  dynamicFilters: Filters = {};
  isOpen: { [key: string]: boolean } = {};
  isFirstTableGeneration = true;
  isLoadingFilters: boolean = false;
  searchQueries: { [key: string]: string } = {}; // Armazena o texto de pesquisa para cada filtro

  constructor(private dataExportService: DataExportService, private snackBar: MatSnackBar, private authService: AuthService) { }

  ngOnInit(): void {
    this.dataExportService.getTables().subscribe(response => {
      this.tables = response.tables; // Aqui populamos as tabelas dinamicamente
    });
  }

  validateAndProceed() {
    if (!this.selectedTable) {
      this.showSnackBar('Selecione uma tabela antes de continuar.', true);
      return;
    }
    this.goToNextStep();
  }  

  onTableChange() {
    // Resetando as colunas selecionadas e filtros ao selecionar uma nova tabela
    this.selectedColumns = [];  
    this.startDate = null;     
    this.endDate = null;     
    this.isOpen = {};       
    this.dynamicFilters = {}; 
  
    if (this.selectedTable) {
      this.dataExportService.getFilters(this.selectedTable).subscribe(response => {
        // Atualiza as colunas disponíveis com a descrição
        this.availableColumns = response.map((filter: any) => ({
          name: filter.descriptionRep, // Exibe apenas a descrição da coluna
          fieldRep: filter.fieldRep
        }));
      });
    }
  }  

  moveToSelected(column: any): void {
    this.availableColumns = this.availableColumns.filter(c => c !== column);
    this.selectedColumns.push(column);
  }
  
  moveToAvailable(column: any): void {
    this.selectedColumns = this.selectedColumns.filter(c => c !== column);
    this.availableColumns.push(column);
  }  

  sendFieldsToEndpoint() {
    if (this.selectedColumns.length === 0) {
      console.warn('Nenhuma coluna selecionada para envio!');
      return;
    }
  
    const fields = this.selectedColumns.map(column => column.fieldRep).join(',');
    const reportId = this.selectedTable;
  
    // Inicia o estado de carregamento
    this.isLoadingFilters = true;
  
    this.dataExportService.sendFields(reportId, fields).subscribe(
      (response: DynamicFilters) => {
        // Verifica se a resposta/ contém filtros
        if (response && response.filters) {
          // Filtra apenas os filtros que possuem valores válidos
          this.dynamicFilters = response.filters
            .filter((filter) => Array.isArray(filter.values) && filter.values.length > 0)
            .reduce((acc: any, filter: any) => {
              acc[filter.fieldRep] = filter.values.map((value: string) => ({
                value: value,
                selected: false // Inicializa o 'selected' como false
              }));
              return acc;
            }, {});
        } else {
          console.error('Filtros não encontrados na resposta.');
          this.dynamicFilters = {}; // Garante que não fique com dados antigos
        }
  
        // Finaliza o estado de carregamento
        this.isLoadingFilters = false;
      },
      (error) => {
        console.error('Erro ao enviar os fields:', error);
  
        // Finaliza o estado de carregamento em caso de erro
        this.isLoadingFilters = false;
      }
    );
  }    
  
  goToPreviousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  goToNextStep() {
    if (this.currentStep === 2) {
      this.sendFieldsToEndpoint();
    }

    if (this.currentStep < 4) {
      this.currentStep++;
    }
  }

  toggleAccordion(filter: string) {
    this.isOpen[filter] = !this.isOpen[filter];

    if (!this.isOpen[filter]) {
      this.searchQueries[filter] = '';
    }
  }  

  selectedFilters() {
    const filters = {};
  
    this.selectedColumns.forEach(column => {
      const field = column.fieldRep;
      if (this.dynamicFilters[field]) {
        // Filtra os valores selecionados
        const selectedValues = this.dynamicFilters[field].filter((value: any) => value.selected);
        if (selectedValues.length > 0) {
          filters[field] = selectedValues.map((value: any) => value.value);
        }
      }
    });
  
    return filters;
  }  

  filterValuesBySearch(fieldRep: string) {
    const query = this.searchQueries[fieldRep] || ''; // Obtém a query específica do filtro
    if (!query) {
      return this.dynamicFilters[fieldRep]; // Retorna todos os valores se não houver pesquisa
    }
  
    const queryLowerCase = query.toLowerCase();
    return this.dynamicFilters[fieldRep].filter((filter: any) => {
      const filterValue = String(filter.value || ''); // Garante que o valor seja uma string
      return filterValue.toLowerCase().includes(queryLowerCase);
    });
  }  
  
  generateTable() {
    const selectedFilters = this.selectedFilters(); // Obtém os filtros selecionados
    const reportId = this.selectedTable; // Supondo que o ID da tabela seja o ID do relatório
  
    if (!reportId) {
      console.error('Report ID não foi selecionado!');
      this.showSnackBar('Selecione uma tabela antes de gerar o relatório.', true);
      return;
    }
  
    // Construir a URL com as datas startDate e endDate
    const startDateParam = this.startDate ? `&startDate=${this.startDate}` : '';
    const endDateParam = this.endDate ? `&endDate=${this.endDate}` : '';
  
    const filtersToSend = selectedFilters;

  
    this.dataExportService.generateReport(reportId, filtersToSend, this.currentPage, this.pageSize, startDateParam, endDateParam).subscribe(
      (response: any) => {
        this.generatedData = response.content || []; // Dados da tabela
        this.totalPages = response.totalPages || 0; // Total de páginas
  
        if (this.generatedData.length === 0) {
          // Exibe mensagem de erro se não houver dados e impede o avanço
          this.showSnackBar('Tabela sem dados. Ajuste os filtros e tente novamente.', true);
          return;
        }
  
        this.goToNextStep(); // Avança para o próximo passo
  
        // Mostra a mensagem de sucesso apenas na primeira geração da tabela
        if (this.isFirstTableGeneration) {
          this.showSnackBar('Tabela gerada com sucesso!', false);
          this.isFirstTableGeneration = false; // Após a primeira exibição, desabilita futuras mensagens
        }
      },
      (error) => {
        console.error('Erro ao gerar a tabela:', error);
        this.showSnackBar('Erro ao gerar a tabela. Tente novamente.', true);
      }
    );
  }      

  changePage(direction: 'previous' | 'next'): void {
    if (direction === 'previous' && this.currentPage > 0) {
      this.currentPage--;
    } else if (direction === 'next' && this.currentPage < this.totalPages - 1) {
      this.currentPage++;
    }
  
    this.pageInput = this.currentPage + 1; // Atualiza o valor do campo de entrada
    this.generateTable();
  }

  jumpToPage(): void {
    const page = this.pageInput - 1; // Ajusta para índice baseado em zero
    if (page >= 0 && page < this.totalPages) {
      this.currentPage = page;
      this.generateTable();
    } else {
      // Retorna ao valor atual se a entrada for inválida
      this.pageInput = this.currentPage + 1;
    }
  }

  /**
 * Verifica se o valor é uma data válida.
 * @param value O valor a ser verificado.
 * @returns true se for uma data, false caso contrário.
 */
  isDate(value: any): boolean {
    // Verifica se o valor é uma string de data válida ou um objeto Date
    if (typeof value === 'string') {
      const parsedDate = Date.parse(value);
      return !isNaN(parsedDate) && value.includes('-'); // Verifica se contém formato de data (ex: YYYY-MM-DD)
    }
  
    // Verifica se é um objeto Date
    return value instanceof Date;
  }  

/**
 * Formata a data para um formato mais legível.
 * @param date A data a ser formatada.
 * @returns A data formatada ou '0' caso seja 0.
 */
  formatDate(date: any): string {
    if (!this.isDate(date)) {
      return date; // Retorna o valor original se não for uma data válida
  }

  // Formata a data caso seja válida
  const dateObj = new Date(date);
  return dateObj.toLocaleString('pt-BR', {
      weekday: 'short', // Exibe o dia da semana abreviado
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  }

  exportData(): void {
    const selectedFilters = this.selectedFilters();
    const reportId = this.selectedTable;
    const email = this.authService.getCurrentUser().email;  // Pegando o e-mail do usuário logado
    // Construir a URL com as datas startDate e endDate
    const startDateParam = this.startDate ? `&startDate=${this.startDate}` : '';
    const endDateParam = this.endDate ? `&endDate=${this.endDate}` : '';
    // Chama o método no service para gerar o relatório
    this.dataExportService.generateReportExport(reportId, selectedFilters, this.selectedColumns.map(col => col.name), startDateParam, endDateParam, email)
      .subscribe(
        (response: any) => {
          console.log('Relatório gerado com sucesso:', response);
          this.showSnackBar(`Relatório gerado com sucesso! O relatório será enviado para o seu e-mail: ${email}`, false);
        },
        (error) => {
          console.error('Erro ao gerar o relatório:', error);
          this.showSnackBar('Erro ao gerar o relatório. Tente novamente.', true);
        }
      );
  }  
  
  showSnackBar(message: string, isError: boolean): void {
    this.snackBar.open(message, 'Fechar', {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: isError ? 'failure-bar-container' : 'success-bar-container',
    });
  }
}
