<div class="container mt-1">
  <h2 class="mb-4 ticket-title" >Tickets Finalizados</h2>
  <div *ngIf="tickets.length === 0" class="text-center mt-4">
    <p>Nenhum ticket finalizados encontrado.</p>
  </div>

  <div class="row">
    <div *ngFor="let ticket of tickets" class="col-md-4">
      <div class="ticket-card card shadow-lg mb-4" style="cursor: pointer;">
        <div class="card-body">
          <span class="badge badge-status">{{ getStatusLabel(ticket.status) }}</span>
          <h6 class="ticket-subject"><strong>{{ ticket.supportSubject || 'Carregando...' }}</strong></h6>
          <p class="card-text">
            <strong>Data Reportada:</strong> {{ ticket.reportedDate | date:'short' }}<br>
            <strong>Data Respondida:</strong> {{ ticket.supportDate | date:'short' }}<br>
          </p>
          <button class="btn btn-info btn-lg" (click)="openTicket(ticket)">+ Informações</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="selectedTicket" class="overlay">
  <div class="form-response-container">
    <p><strong>Assunto:</strong> {{ selectedTicket.supportSubject || 'Carregando...' }}</p>
    <p><strong>Status:</strong> {{ getStatusLabel(selectedTicket.status) }}</p>
    <p><strong>Data Reportada:</strong> {{ selectedTicket.reportedDate | date:'short' }}</p>
    <p><strong>Prazo:</strong> {{ selectedTicket.deadline | date:'short' }}</p>
    <p><strong>Data Respondida:</strong> {{ selectedTicket.supportDate | date:'short' }}</p>
    <p><strong>Descrição:</strong></p>
    <div class="scrollable">
      <p>{{ selectedTicket.supportDescription || 'Sem descrição' }}</p>
    </div>
    <p><strong>Resposta:</strong></p>
    <div class="scrollable">
      <p>{{ selectedTicket.answer }}</p>
    </div>
    <button class="btn btn-secondary" (click)="closeModal()">Fechar</button>
  </div>
</div>

<div class="pagination-container" *ngIf="totalPages > 1 && tickets.length > 0">
  <button class="button-pages" (click)="goToPreviousPage()" [disabled]="currentPage === 1">
    <span>Anterior</span>
  </button>
  <div class="page-input-container">
    <span>Página</span>
    <input type="number" [(ngModel)]="currentPage" (ngModelChange)="onPageChange($event)" min="1" [max]="totalPages"
      class="page-input" />
    <span>de {{ totalPages }}</span>
  </div>

  <button class="button-pages" (click)="goToNextPage()" [disabled]="currentPage === totalPages">
    <span>Próximo</span>
  </button>
</div>


