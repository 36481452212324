import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Validators } from 'src/app/components/smart-table/utils/Validators';
import { SmartTableRow } from './types/SmartTableRow';
import { SmartTableCard } from './types/SmartTableCard';
import { SmartTableCell } from './types/SmartTableCell';
import { SmartModifiedCell } from './types/SmartModifiedCell';
import { SmartTableHeader } from './types/SmartTableHeader';

@Component({
  selector: 'app-smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush // Otimiza a detecção de mudanças para melhor desempenho
})
export class SmartTableComponent implements OnInit {
  
  // Entrada principal de dados: headers (colunas) e rows (linhas) da tabela
  @Input() data: { headers: SmartTableHeader[], rows: SmartTableRow[] } = { headers: [], rows: [] };
  @Input() title: string = null;

  // Configurações da tabela
  @Input() paginable: boolean = false; // Permite paginação
  @Input() searchable: boolean = false; // Permite busca
  @Input() sortable: boolean = false; // Permite ordenação
  @Input() importable: boolean = false; // Permite importar dados
  @Input() exportable: boolean = false; // Permite exportar dados
  @Input() submitable: boolean = false; // Permite enviar mudanças
  @Input() preview: boolean = false; // Modo de pré-visualização

  // Controle de estado
  @Input() loading: boolean = false; // Indica carregamento de dados
  @Input() currentPage: number = 1; // Página atual
  @Input() itemsPerPage: number = 15; // Quantidade de itens por página
  @Input() totalPages: number = 1; // Total de páginas

  @Input() maxHeight: number = 12; // Altura máxima da tabela em linhas (apenas visual)

  // Cartões adicionais associados à tabela
  @Input() cards: SmartTableCard[] = [];

  // Callbacks para ações específicas
  @Input() importDataCallback!: () => void; // Callback para importar dados
  @Input() exportDataCallback!: ({ search, sortBy, sortDirection }) => void; // Callback para exportar dados
  @Input() openModal!: ({ rowId, value, field }: { rowId: string, field: string, value: any }) => void; // Abre modal em célula clicável

  // Eventos de saída
  @Output() onPageChange = new EventEmitter<{ page: number, size: number, search: string, isPreview: boolean, sortBy: string[], sortDirection: string[] }>();
  @Output() onSubmitChanges = new EventEmitter<{ isPreview: boolean, data: SmartTableRow[] }>(); // Emite alterações feitas na tabela

  // Dados paginados
  paginatedData: SmartTableRow[] = [];

  // Mapas para rastrear células inválidas e modificadas
  invalidMap: Set<SmartTableCell> = new Set();
  modifiedMap: Set<SmartModifiedCell> = new Set();
  invalidArray: Array<{ cell: SmartTableCell, page: number }> = [];
  currentErrorIndex = 0; // Índice do erro atual para navegação

  searchQuery: string = ''; // Termo de busca atual
  searchSubject: Subject<string> = new Subject<string>(); // Controle de debounce na busca
  sortCriteria: { field: string; direction: string }[] = []; // Lista de critérios de ordenação

  constructor() {}

  ngOnInit(): void {
    // Se estiver no modo de pré-visualização, carrega a primeira página e conta erros
    if (this.preview) {
      this.fetchData(this.currentPage);
      this.countErrors();
    }

    // Garante que a página atual não excede o total de páginas
    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }

    // Configura debounce para busca, emitindo eventos apenas após 500ms de inatividade
    this.searchSubject
      .pipe(debounceTime(500))
      .subscribe((query) => {
        const sortFields = this.sortCriteria.map((criteria) => criteria.field);
        const sortDirections = this.sortCriteria.map((criteria) => criteria.direction);
        this.onPageChange.emit({
          page: this.currentPage,
          size: this.itemsPerPage,
          isPreview: this.preview,
          search: query.trim().toLowerCase(),
          sortBy: sortFields,
          sortDirection: sortDirections
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Sempre que os dados mudam, aplica destaques na busca
    if (changes['data']) {
      this.applySearchHighlight();
    }
  }

  // Carrega dados para uma página específica
  fetchData(page: number): void {
    if (page < 1 || page > this.totalPages) {
      return; // Valida a página solicitada
    }

    const sortFields = this.sortCriteria.map((criteria) => criteria.field);
    const sortDirections = this.sortCriteria.map((criteria) => criteria.direction);

    if (this.preview) {
      // Paginação local no modo de pré-visualização
      this.totalPages = Math.ceil(this.data.rows.length / this.itemsPerPage);
      const startIndex = (page - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      this.paginatedData = this.data.rows.slice(startIndex, endIndex);
    } else {
      // Emite evento para carregar página do backend
      this.onPageChange.emit({
        page,
        size: this.itemsPerPage,
        isPreview: this.preview,
        search: this.searchQuery,
        sortBy: sortFields,
        sortDirection: sortDirections
      });
    }
  }

  // Vai para uma página específica e atualiza os dados
  goToPage(page: number): void {
    page = Number(page);
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.fetchData(this.currentPage);
    }
  }

  // Importa dados chamando o callback configurado
  onImport(): void {
    if (this.importDataCallback) {
      this.importDataCallback();
    }
  }

  // Exporta dados chamando o callback configurado
  onExport(): void {
    const sortFields = this.sortCriteria.map((criteria) => criteria.field);
    const sortDirections = this.sortCriteria.map((criteria) => criteria.direction);
    if (this.exportDataCallback) {
      this.exportDataCallback({ search: this.searchQuery, sortBy: sortFields, sortDirection: sortDirections });
    }
  }

  // Atualiza os dados de busca usando debounce
  onSearchInputChange(): void {
    this.searchSubject.next(this.searchQuery.trim().toLowerCase());
  }

  // Aplica ordenação aos dados
  onSort(header: SmartTableHeader): void {
    if (this.sortable && header.sortable) {
      const field = header.field;
      const existingSort = this.sortCriteria.find((criteria) => criteria.field === field);

      if (existingSort) {
        existingSort.direction = existingSort.direction === 'ASC' ? 'DESC' : '';
        if (!existingSort.direction) {
          this.sortCriteria = this.sortCriteria.filter((criteria) => criteria.field !== field);
        }
      } else {
        this.sortCriteria.push({ field, direction: 'ASC' });
      }

      this.fetchData(this.currentPage);
    }
  }

  isCurrentSortField(field: string): boolean {
    return this.sortable && this.sortCriteria.some((criteria) => criteria.field === field);
  }

  getSortDirection(field: string): string | null {
    // Retorna a direção atual para o campo
    const criteria = this.sortCriteria.find((criteria) => criteria.field === field);
    return criteria ? criteria.direction : null;
  }

  validateCell(cell: SmartTableCell, rowIndex: number): boolean {
    const message: string[] = [];

    const validators = Validators.getResolvedValidators(cell.validators);
  
    // Etapa 1
    if (validators) {
      for (const validator of validators) {
        const { isValid, errorMessage } = validator(cell);
        if (!isValid) {
          message.push(errorMessage);
        }
      }
    }

    // Etapa 2
    const isValid = message.length === 0;

    // Etapa 3
    if (cell.readonly && !cell.tooltip) {
      message.unshift('Este campo está bloqueado para edição.');
    }
    
    // Etapa 4
    if(cell.tooltip) {
      message.unshift(cell.value)
    }
    
    cell.messages = message.join('\n');

    // Epata 5
    if (!isValid) {
      const page = Math.floor(rowIndex / this.itemsPerPage) + 1;

      if (!this.invalidMap.has(cell)) {
        this.invalidArray.push({ cell, page });
      }
    }

    this.updateInvalidMap(cell, isValid)
    
    return isValid;
  }

  countErrors(): void {
    this.invalidArray = []; // Reinicializa o array
  
    this.data.rows.forEach((row, rowIndex) => {
      row.values.forEach((cell) => {
        const isValid = this.validateCell(cell, rowIndex);
        this.updateInvalidMap(cell, isValid);
      });
    });

    this.currentErrorIndex = 0; // Reseta o índice ao contar erros novamente
  }
  
  // Atualiza o mapa de células inválidas
  private updateInvalidMap(cell: SmartTableCell, isValid: boolean): void {
    if (!isValid) {
      this.invalidMap.add(cell); // Adiciona células inválidas
    } else if (this.invalidMap.has(cell)) {
      this.invalidMap.delete(cell); // Remove células válidas
    }
  }

  // TODO: Essa função poderia ser menos verbosa.
  private applySearchHighlight(): void {
    this.data.rows.forEach((row) => {
      row.values.forEach((cell) => {
        const cellValue = cell.value != null ? cell.value.toString().toLowerCase() : '';
        if (this.searchQuery !== '' && cellValue.includes(this.searchQuery.toLowerCase())) {
          cell.isHighlighted = true; // Adiciona uma flag para destacar
        } else {
          cell.isHighlighted = false; // Remove a flag caso não corresponda
        }
      });
    });
  }

  navigateErrors(direction: 'prev' | 'next'): void {
    // Atualiza o índice antes de acessar o erro
    if (direction === 'prev' && this.currentErrorIndex > 0) {
      this.currentErrorIndex--;
    } else if (direction === 'next' && this.currentErrorIndex < this.invalidArray.length - 1) {
      this.currentErrorIndex++;
    }
  
    const currentError = this.invalidArray[this.currentErrorIndex];
  
    // Carrega a página correspondente ao erro, se necessário
    if (currentError && currentError.page !== this.currentPage) {
      this.fetchData(currentError.page);
      this.currentPage = currentError.page;
    }
  
    // Destaca a célula após garantir que a página está carregada
    if (currentError) {
      setTimeout(() => this.highlightCell(currentError.cell), 0);
    }
  }

  highlightCell(cell: SmartTableCell): void {
    const element = document.querySelector(`[data-cell-id="${cell.column}-${cell.value}"]`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.classList.add('highlight');
      setTimeout(() => element.classList.remove('highlight'), 1500); // Remove o destaque após 1.5s
    }
  }
  
  markCellAsModified(cell: SmartTableCell, rowId: string): void {
    const modifiedCell: SmartModifiedCell = { rowId, cell };
    const existingCell = Array.from(this.modifiedMap).find(
      (item) => item.rowId === rowId && item.cell.cellId === cell.cellId
    );

    if (!existingCell) {
      this.modifiedMap.add(modifiedCell);
    }
  }
  
  onInputUpdate(event: Event, cell: SmartTableCell, rowId: string): void {
    const target = event.target as HTMLInputElement;
    cell.value = target.value; // Atualiza o valor da célula
    this.markCellAsModified(cell, rowId); // Passa o ID da linha
  }

  onCellClick(event: Event, cell: SmartTableCell, rowId: string) {
    if (cell.clickable) {
      const target = event.target as HTMLInputElement;
      this.openModal({ rowId, value: target.value, field: cell.field });
    }
  }

  // TODO: Essa função poderia ser menos verbosa.
  submitChanges(): void {
    if (this.preview) {
      // Em pré-visualização, retorna todos os dados da tabela
      this.onSubmitChanges.emit({ isPreview: this.preview, data: this.data.rows });
    } else {
      if (this.modifiedMap.size > 0) {
        // Agrupa as células modificadas pela chave rowId
        const groupedRows: SmartTableRow[] = Array
          .from(this.modifiedMap)
          .reduce((result, modifiedCell) => {
            const { rowId, cell } = modifiedCell;
            let row = result.find(r => r.id === rowId);
    
            if (!row) {
              row = { id: rowId, values: [] };
              result.push(row);
            }
    
            row.values.push(cell);
            return result;
        }, []);
    
        // Emite as células modificadas agrupadas
        this.onSubmitChanges.emit({ isPreview: this.preview, data: groupedRows });
  
        // Limpa o mapa de modificações
        this.modifiedMap.clear();
      }
    }
  }

  // TODO: As duas funções abaixo são bem parecidas e utilizam a mesma verificação.
  isModified(cell: SmartTableCell, rowId: string): boolean {
    for (const modifiedCell of this.modifiedMap) {
      if (
        modifiedCell.rowId === rowId &&
        modifiedCell.cell.cellId === cell.cellId &&
        modifiedCell.cell.value !== ''
      ) {
        return true;
      }
    }
    return false;
  }
  
  getModified(cell: SmartTableCell, rowId: string): string {
    for (const modifiedCell of this.modifiedMap) {
      if (
        modifiedCell.rowId === rowId &&
        modifiedCell.cell.cellId === cell.cellId &&
        modifiedCell.cell.value !== ''
      ) {
        return modifiedCell.cell.value;
      }
    }
    return cell.value;
  }
  
  trackByRow(index: number, row: any): any {
    return row.id || index;
  }
  
  trackByCell(index: number) {
    return index;
  }
}
