import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserGuideService {
  private userUrl: string = 'https://fidere-documentation.s3.us-east-2.amazonaws.com/manual/Manual_Usuario-Fidere.pdf';
  private adminUrl: string = 'https://fidere-documentation.s3.us-east-2.amazonaws.com/manual/Manual_adm_fidere.pdf';

  constructor() {}

  getUserUrl(): string {
    return this.userUrl;
  }

  getAdminUrl(): string {
    return this.adminUrl;
  }
}