import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environment';

export interface InputRecurrence {
  startDate?: string;
  endDate?: string;
  type?: string;
  director?: string;
}

export interface Recurrence {
  designation: string;
  type: string;
  referenceDate: string;
  director: string;
  volume: number;
  incident: string;
  cause: string;
  action: string;
  deadline: string;
}

export interface RecurrenceOperationResult {
  created: number;
  updated: number;
  total: number;
}

@Injectable({
  providedIn: 'root',
})
export class RecurrencesService {

  private readonly baseUrl; // Substitua pela URL base da sua API
  private readonly pubisherUrl;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
    this.pubisherUrl = environment.publisherUrl;
  }

  /**
   * Busca recurrences paginados com filtros.
   */
  getRecurrences(
    input: InputRecurrence,
    page: number,
    size: number,
    search: string,
    sortBy: string[],
    sortDirection: string[],
    userId: any
  ): Observable<any> {
    
    let params = new HttpParams()
      .set('page', page)
      .set('size', size)
      .set('userId', userId);

    if (search) params = params.set('search', search); 
    if (input.startDate) params = params.set('startDate', input.startDate);
    if (input.endDate) params = params.set('endDate', input.endDate);
    if (input.type) params = params.set('type', input.type);
    if (input.director) params = params.set('director', input.director);
    if (sortBy) params = params.set('sortBy', sortBy.join(','));
    if (sortDirection) params = params.set('sortDirections', sortDirection.join(','));  
    return this.http.get<any>(`${this.baseUrl}/vandalism/recurrences`, { params });
  }
  
  /**
   * Busca recurrences paginados com filtros.
   */
  sendRecurrences(
    input: InputRecurrence,
    userId: number,
    search: string,
    sortBy: string[],
    sortDirection: string[] 
  ): Observable<any> {
    
    let params = new HttpParams()
      .set("userId", userId);

    if (search) params = params.set('search', search); 
    if (input.startDate) params = params.set('startDate', input.startDate);
    if (input.endDate) params = params.set('endDate', input.endDate);
    if (input.type) params = params.set('type', input.type);
    if (input.director) params = params.set('director', input.director);
    if (sortBy) params = params.set('sortBy', sortBy.join(','));
    if (sortDirection) params = params.set('sortDirections', sortDirection.join(','));
    return this.http.get<any>(`${this.pubisherUrl}/publisher/recurrences/export`, { params });
  }

  /**
   * Cria novos recurrences.
   */
  createRecurrences(input: Recurrence[]): Observable<{ message: string }> {
    return this.http.post<any>(`${this.baseUrl}/vandalism/recurrences`, input);
  }

  /**
   * Atualiza recurrences existentes.
   */
  updateRecurrences(input: Recurrence[]): Observable<{ message: string }> {
    return this.http.put<any>(`${this.baseUrl}/vandalism/recurrences`, input);
  }
}
