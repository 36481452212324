import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CriticalRoute, Marker, Route } from "../maps/preventive-map/types/preventive.type";
import { environment } from "src/environment";

export interface InputPreventive {
  families?: string;
  classification?: string;
  clusters?: string;
  directors?: string;
  states?: string;
  type?: string;
  routeIds?: string;
}

export interface OutputPreventive {
  filters: {
    classification: string[];
    families: string[];
    states: string[];
    clusters: string[];
    type: string[];
    routeIds: string[];
  };
  markers: Marker[];
  routes: Route[];
}

export interface InputCriticalRoute {
  families?: string;
  types?: string;
  status?: string;
  regionals?: string;
  directors?: string;
  states?: string;
}

export interface OutputCriticalPreventive {
  filters: {
    status: string[];
    families: string[];
    type: string[];
    regionals: string[];
    directors: string[];
    states: string[];
  };
  markers: Marker[];
  data: CriticalRoute[];
}

@Injectable({
  providedIn: "root",
})
export class PreventiveService {

  private readonly baseUrl; // Substitua pela URL base da sua API  

  constructor(private http: HttpClient) {    
    // this.baseUrl = "http://localhost:8769";
    this.baseUrl = environment.baseUrl;
  }

  getPreventives(input: InputPreventive): Observable<any> {
    let params = new HttpParams();
    if (input.families) params = params.set("families", input.families);
    if (input.directors) params = params.set("directors", input.directors);
    if (input.states) params = params.set("states", input.states);
    if (input.classification) params = params.set("classification", input.classification);
    if (input.type) params = params.set("type", input.type);
    if (input.routeIds) params = params.set("routeIds", input.routeIds);
    return this.http.get<OutputPreventive>(`${this.baseUrl}/vandalism/preventives`, { params });
  }

  createRoute(input: {
    routeId: string;
    // points: Array<{ lat: number; lng: number }>;
  }): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/vandalism/preventives`, input);
  }

  getCriticalRoutes(
    input: InputCriticalRoute,
    page: number,
    size: number,
    search: string,
    sortBy: string[],
    sortDirection: string[],
    userId: any
  ): Observable<{
    filters: Record<string, Array<string>>;
    data: any;
    settings: any;
    totalPages: number;
    cards: any;
  }> {
    let params = new HttpParams()
      .set("userId", userId)
      .set("page", page)
      .set("size", size);
  
    if (search) params = params.set('search', search); 
    if (input.status) params = params.set('status', input.status);
    if (input.types) params = params.set('types', input.types);
    if (input.families) params = params.set('families', input.families);
    if (input.regionals) params = params.set("regionals", input.regionals);
    if (input.directors) params = params.set("directors", input.directors);
    if (input.states) params = params.set("states", input.states);
    if (sortBy) params = params.set('sortBy', sortBy.join(','));
    if (sortDirection) params = params.set('sortDirections', sortDirection.join(','));
    return this.http.get<any>(`${this.baseUrl}/vandalism/preventives/critical-routes`, { params });
  }
}
