import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environment';
import { ImageVerificationNsp } from '../interfaces/dtos/ImageValidation';
import { Observable } from 'rxjs';
import { ViewGraphContent } from '../pages/images/analitico-images/analitico.component';
import { InputFilter } from '../pages/images/interfaces/InputFilter';

@Injectable({
  providedIn: 'root',
})
export class ImageVerificationService {
  private readonly baseUrl;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  /**
   * Busca verifications paginados com filtros.
   */
  getVerificationImage(
    input: InputFilter,
    page: number,
    size: number,
    search: string,
    sortBy: string[],
    sortDirection: string[]
  ): Observable<any> {
    const params = this.buildParams({ input, page, size, search, sortBy, sortDirection });
    return this.http.get<any>(`${this.baseUrl}/vandalism/image/verifications`, { params });
  }

  /**
   * Busca imagens analíticas com filtros.
   */
  getAnalyticalImage(input: InputFilter, view: ViewGraphContent): Observable<any> {
    const params = this.buildParams({ input, view });
    return this.http.get<any>(`${this.baseUrl}/vandalism/image/analytical`, { params });
  }

  /**
   * Busca tabela de técnicos analíticos.
   */
  getAnalyticalTechnicianTable(
    input: InputFilter,
    page: number,
    size: number,
    search: string,
    sortBy: string[],
    sortDirection: string[]
  ): Observable<any> {
    const params = this.buildParams({ input, page, size, search, sortBy, sortDirection });
    return this.http.get<any>(`${this.baseUrl}/vandalism/image/analytical/technicians`, { params });
  }

  /**
   * Valida imagens com base nos parâmetros enviados.
   */
  getImages(input: ImageVerificationNsp.Params): Observable<ImageVerificationNsp.Response> {
    return this.http.post<ImageVerificationNsp.Response>(`${this.baseUrl}/vandalism/image/validation`, input);
  }

  /**
   * Busca tickets por ID.
   */
  getTicketsById(sequenceId: string): Observable<any> {
    const params = new HttpParams().set('sequenceId', sequenceId);
    return this.http.get<any>(`${this.baseUrl}/vandalism/analytical/tickets/search`, { params });
  }

  /**
   * Constrói os parâmetros de requisição HTTP.
   */
  private buildParams(options: {
    input?: InputFilter;
    page?: number;
    size?: number;
    search?: string;
    sortBy?: string[];
    sortDirection?: string[];
    view?: ViewGraphContent;
  }): HttpParams {
    let params = new HttpParams();

    // Parâmetros de paginação
    if (options.page !== undefined) params = params.set('page', options.page.toString());
    if (options.size !== undefined) params = params.set('size', options.size.toString());

    // Parâmetros de busca e ordenação
    if (options.search) params = params.set('search', options.search);
    if (options.sortBy?.length) params = params.set('sortBy', options.sortBy.join(','));
    if (options.sortDirection?.length) params = params.set('sortDirections', options.sortDirection.join(','));

    // Parâmetros específicos de InputFilter
    const input = options.input;
    if (input) {
      if (input.startDate) params = params.set('startDate', input.startDate);
      if (input.endDate) params = params.set('endDate', input.endDate);
      if (input.causes) params = params.set('causes', input.causes);
      if (input.directors) params = params.set('directors', input.directors);
      if (input.states) params = params.set('states', input.states);
      if (input.regionals) params = params.set('regionals', input.regionals);
      if (input.clusters) params = params.set('clusters', input.clusters);
      if (input.subclusters) params = params.set('subclusters', input.subclusters);
      if (input.cities) params = params.set('cities', input.cities);
      if (input.validations) params = params.set('validations', input.validations);
      if (input.technicianIds) params = params.set('technicianIds', input.technicianIds);
      if (input.companies) params = params.set('companies', input.companies);
      if (input.families) params = params.set('families', input.families);
    }

    // Parâmetros específicos de ViewGraphContent
    if (options.view) params = params.set('view', options.view);

    return params;
  }
}
