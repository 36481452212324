<div class="container-map-preventiva">

  <div *ngIf="loading" class="loading-screen">
    <div class="loading-animation">
      <div class="spinner-container">
        <div class="spinner"></div>
      </div>
      <p class="loading-title">Carregando...</p>
    </div>
  </div>

  <div class="container-button-close-map">
    <button class="button-close-map" (click)="closeModal()">
      <i class="fa fa-compress"></i>
    </button>
  </div>


  <!-- * HEADER FILTER PAGE -->
  <div class="container-header-page full-screen" [class.bg-transition]="isDivVisible" #headerFilter>
    <div class="header-img-filter col-1">
      <img
        [src]="isDivVisible ? '../../../../assets/images/claro_logo_70.png' : '../../../../assets/images/claro_logo_50.png'"
        (click)="openHeaderFilter()" alt="logo da claro">
    </div>
    <div class="container-header-filters col-11" [@slideInOut]="isDivVisible ? 'in' : 'out'">
      <!-- * TOP FILTER -->
      <div class="container-filters col-12 p-0">
        <div class="col-2">
          <app-select-default key="families" title="FAMÍLIA" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.families" (event)="onChange($event)">
          </app-select-default>
        </div>

        <!-- [selectedItems]="selectedFilters.value.directors" -->
        <div class="col-2">
          <app-select-default key="classification" title="CLASIFICAÇÃO" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.classification" (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="type" title="TIPO" [searchFilter]="true" [dropdownList]="dropdownFilters.value?.type"
            (event)="onChange($event)">
          </app-select-default>
        </div>

        <!-- [selectedItems]="selectedFilters.value.states" -->
        <div class="col-2">
          <app-select-default key="states" title="ESTADO" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.states" (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="routeIds" title="ROTAS" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.routeIds" (event)="onChange($event)">
          </app-select-default>
        </div>
      </div> <!-- TOP FILTER -->
    </div>

    <!-- <div class="container-editor-mode">    
      <div id="routeInfoBox" class="info-box-route hidden">
        <div class="info-box-buttons">
          <button class="btn-default-small" (click)="closeRouteInfoBox()">
            {{ isEditorEnabled ? 'Desabilitar Modo Editor' : 'Criar atividade' }}
          </button>
          <button class="btn-close-default" (click)="closeRouteInfoBox()">
            <i class="fa fa-times"></i>
          </button>
        </div>
  
        <div *ngIf="isEditorEnabled" class="info-box-content-route">        
          <div class="content-info-title">
            <span>{{ selectedSection?.title }}</span>
          </div>          
        </div>        
      </div>
    </div> -->    
  </div>

  <!-- <div *ngIf="!loading" class="container-list-routes"> 
    <div class="list-routes" [@fadeInOutAnimation]>      
      <mat-tree 
        [dataSource]="dataSource" 
        [treeControl]="treeControl" 
        class="list-routes-content"
      >
        Nó pai
        <mat-tree-node 
          *matTreeNodeDef="let node" matTreeNodeToggle
          class="route-content-father"
        >
          <button mat-icon-button matTreeNodeToggle>
            <mat-icon [@fadeInOutAnimation]>
              {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
          {{ node.name }}
        </mat-tree-node>
      
        Nó folha
        <mat-tree-node 
          *matTreeNodeDef="let node; when: isLeaf" matTreeNodePadding
          class="route-content-children"
        >
          <button mat-icon-button disabled>
            <mat-icon>
              {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
          {{ node.name }} {{ node.count }}
          <button mat-icon-button (click)="toggleRouteVisibility(node.route.id, !node.route.enabled, map)">
            <mat-icon class="icon-visibility">{{ node.route.enabled ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-tree-node>

        Nó folha (rota individual)
        <mat-tree-node *matTreeNodeDef="let node; when: isLeaf" matTreeNodePadding>
          <button mat-icon-button disabled></button>
          {{ node.name }}
          <button mat-icon-button (click)="onRouteClick(node.route)">
            <mat-icon>visibility</mat-icon>
          </button>
        </mat-tree-node>
      </mat-tree>      
    </div>     
  </div> -->

  <agm-map
    [latitude]="mapConfig.center?.lat || getFirstRouteCenter().lat"
    [longitude]="mapConfig.center?.lng || getFirstRouteCenter().lng"  
    [streetViewControl]="false"
    [zoom]="mapConfig.zoom"
    [disableDefaultUI]="mapConfig.disableDefaultUI"
    [maxZoom]="mapConfig.maxZoom"
    (mapReady)="onMapReady($event)"
    style="width: 100%; height: 100%;"
  ></agm-map>

  <div class="map-legend" *ngIf="!loading && (legendItems | async)?.length > 0" [@fadeInOutAnimation]>
    <div class="legend-content">
      <div class="item-legend" *ngFor="let item of legendItems | async">
        <span class="icon-legend">
          <img [src]="iconMap[getIconKey(item.icon)]">
        </span>
        <span class="name-legend">{{ item.icon.includes('null') ? 'Não Classificado' : item.name }}</span>
      </div>
    </div>
  </div>
</div>