import { SmartTableCell } from "../types/SmartTableCell";

export interface ResponseValidator {
  isValid: boolean,
  errorMessage?: string
}

export class Validators {

  static notEmpty(cell: SmartTableCell): ResponseValidator {
    const value = cell.value;
    const isValid = value !== null && value !== undefined && value.toString().trim() !== '';
    return {
      isValid,
      errorMessage: isValid ? undefined : 'O campo não pode estar vazio.',
    };
  }

  static isNumber({ value }: SmartTableCell): ResponseValidator {
    const isValid = value === null || value === undefined || !isNaN(value);
    return {
      isValid,
      errorMessage: isValid ? undefined : 'O valor deve ser um número.',
    };
  }

  static isValidDate({ value }: SmartTableCell): ResponseValidator {
    if (!value) {
      return { isValid: true };
    }
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    const isValid = regex.test(value);
    return {
      isValid,
      errorMessage: isValid ? undefined : 'A data deve estar no formato DD/MM/AAAA.',
    };
  }

  static isInOptions({ value, options }: SmartTableCell): ResponseValidator {
    const isValid = !value || options.includes(value);
    return {
      isValid,
      errorMessage: isValid ? undefined : `O valor deve ser uma das opções: ${options.join(', ')}.`,
    };
  }

  // Mapa de validadores
  static validatorsMap: Record<string, (...args: any[]) => ResponseValidator> = {
    NOT_EMPTY: Validators.notEmpty,
    IS_NUMBER: Validators.isNumber,
    IS_VALID_DATE: Validators.isValidDate,
    IS_IN_OPTIONS: Validators.isInOptions
  };

  // Método para resolver validadores
  static getResolvedValidators(validatorKeys: string[]): Array<(...args: any[]) => ResponseValidator> {
    if (!validatorKeys) return [];
    return validatorKeys
      .map((validator) => Validators.validatorsMap[validator])
      .filter((value) => value !== undefined);
  }
}
