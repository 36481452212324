import { format } from "date-fns";

export class Parses {

  static date(input: number | string | undefined): string {
    if (typeof input === 'string') {
      return input;
    }

    if (typeof input === 'number') {
      const startDate = new Date(1900, 0, 1);
      const formatedDate = new Date(startDate.getTime() + (input - 2) * 24 * 60 * 60 * 1000);
      return format(formatedDate, 'dd/MM/yyyy');
    }

    return ''; // Retorna uma string vazia se o valor for inválido
  }

  static validation(value: boolean): string {
    return value ? "✔️" : "❌";
  }
  
  // Método auxiliar para parse de datas
  static validationindicator(value: string): string[] {
    if (value !== null && typeof value === 'string') {
      return value.split(',');
    } 
    return [];
  }

  // Mapa de validadores
  static parsesMap = {
    PARSE_DATE: Parses.date,
    PARSE_VALIDATION: Parses.validation,
    PARSE_VALIDATION_INDICATOR: Parses.validationindicator
  };

  static parseValue(parse: string, value: string, defaultValue: string = '') {
    if (this.parsesMap[parse]) {
      return this.parsesMap[parse](value);
    }

    return defaultValue;
  }
}
