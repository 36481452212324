import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fadeInOutAnimation, slideInOutAnimation } from 'src/app/animations/animations';
import { IColors } from 'src/app/interfaces';
import { ThemeService } from 'src/app/services/theme.service';
import { icons } from 'src/app/utils/icons';
import { RotaService, Route, RouteResponse, Filters, RouteMap, RouteItem } from 'src/app/services/rota.service';

@Component({
  selector: 'app-rota',
  templateUrl: './rota.component.html',
  styleUrls: ['./rota.component.sass'],
  animations: [slideInOutAnimation, fadeInOutAnimation]
})
export class RotaComponent implements OnInit {
  expected: Route;
  realized: Route;
  
  filters: Filters;

  routeData: RouteResponse;

  options = { colors: { expected: 'red', realized: 'green' } };
  mapConfig = { zoom: 5, zoomControl: true, streetViewControl: false, fullscreenControl: false, optimizeWaypoints: false, center: { lat: -15.8517, lng: -48.5799 } };
  expectedRenderOptions = { suppressMarkers: false, draggable: false, polylineOptions: { strokeColor: this.options.colors.expected, strokeWeight: 4 } };
  realizedRenderOptions = { suppressMarkers: false, draggable: false, polylineOptions: { strokeColor: this.options.colors.realized, strokeWeight: 3 } };

  isDivVisible = false;
  isMapVisible = true;
  loading = false;
  error = false;
  submit = true;
  submitContent = 'Selecione uma rota';
  colors: IColors;
  icons = icons;
  overlayVisible = false;

  activeFilters = { family: '', directors: '', states: '', cities: '', routeId: '' };
  routeNames: string[] = [];
  routeStartTime = 'Informação indisponível';
  routeEndTime = 'Informação indisponível';

  allRoutes: RouteItem[] = []; 
  paginatedData: RouteItem[] = []; 
  itemsPerPage = 7; 
  currentPage = 1; 
  totalPages = 1; 
  searchQuery: string = ''; 
  filteredRoutes: RouteItem[] = []; 

  constructor(private themeService: ThemeService, private snackBar: MatSnackBar, private rotaService: RotaService) {
    this.colors = this.themeService.getColors();
  }

  ngOnInit(): void {
    this.fetchInitialData();
  }

  fetchInitialData(): void {
    this.rotaService.fetchRoutes({}).subscribe({
      next: (response) => {
        this.filters = response.filters;
  
        this.allRoutes = (response.filters.routes || []).map(route => ({
          ...route,
        }));

        this.filteredRoutes = [...this.allRoutes];
  
        this.totalPages = Math.ceil(this.filteredRoutes.length / this.itemsPerPage);
        this.updatePaginatedData();
      },
      error: (err) => {
        console.error('Erro ao buscar dados:', err);
        this.showSnackBar('Erro ao buscar os dados', true);
      },
    });
  }            

  updatePaginatedData(): void {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedData = this.filteredRoutes.slice(startIndex, endIndex);
  }    

  changePage(direction: 'prev' | 'next'): void {
    if (direction === 'prev' && this.currentPage > 1) {
      this.currentPage--;
    } else if (direction === 'next' && this.currentPage < this.totalPages) {
      this.currentPage++;
    }
    this.updatePaginatedData();
  }

  filterTable(): void {
    const query = this.searchQuery.trim().toLowerCase();
  
    if (!query) {
      this.filteredRoutes = [...this.allRoutes];
    } else {
      this.filteredRoutes = this.allRoutes.filter(route =>
        (route.family?.toLowerCase() || '').includes(query) ||
        (route.directors?.toLowerCase() || '').includes(query) ||
        (route.states?.toLowerCase() || '').includes(query) ||
        (route.cities?.toLowerCase() || '').includes(query) ||
        (route.routeName?.toLowerCase() || '').includes(query)
      );
    }
  
    this.totalPages = Math.ceil(this.filteredRoutes.length / this.itemsPerPage);
    this.currentPage = 1; 
    this.updatePaginatedData();
  }    

  getRouteName(dropdown: Filters): string[] {
    return dropdown?.routes?.map(r => r.routeName).filter(Boolean) || [];
  }

  getRouteIdByName(routeName: string): string {
    return this.filters?.routes?.find(route => route.routeName === routeName)?.routeId || '';
  }

  updateFilters(filterKey: string, value: string): void {
    const cleanValue = value.replace(`${filterKey}:`, '');
    if (filterKey === 'routeId') {
      const route = this.filters.routes.find(r => r.routeName === cleanValue);
      this.activeFilters.routeId = route?.routeId || '';
      this.activeFilters.family = route?.family || '';
    } else {
      this.activeFilters[filterKey] = cleanValue;
    }
    this.fetchFilteredData();
  }

  onRouteClick(routeId: string): void {
    this.activeFilters.routeId = routeId;
  
    this.fetchFilteredData();
  
    this.showSnackBar(`Rota ${routeId} selecionada!`, false);
  }  

  fetchFilteredData(): void {
    this.loading = true;
    this.rotaService.fetchRoutes(this.activeFilters).subscribe({
      next: (response) => {
        this.filters = response.filters;
        this.routeData = response;
        const selectedRouteMap = response.routeMap;
        if (this.activeFilters.routeId && selectedRouteMap) {
          this.updateMapData(selectedRouteMap);
          this.submit = false; 
          const hours = response.routeTable?.[0]?.hours.split('-') || [];
          this.routeStartTime = hours[0]?.trim() || 'Informação indisponível';
          this.routeEndTime = hours[1]?.trim() || 'Informação indisponível';
        } else {
          this.submit = true;
        }
        this.loading = false;
      },
      error: (err) => this.handleError(err),
    });
  }  

  updateMapData(routeMap: RouteMap): void {
    this.expected = {
      origin: routeMap.expected.origin,
      destination: routeMap.expected.destination,
      waypoints: routeMap.expected.stops.map(stop => ({ location: { lat: stop.lat, lng: stop.lng } }))
    };
    this.realized = routeMap.realized
      ? {
        origin: routeMap.realized.origin,
        destination: routeMap.realized.destination,
        waypoints: routeMap.realized.stops.map(stop => ({ location: { lat: stop.lat, lng: stop.lng } }))
      }
      : null;

    this.mapConfig.center = { lat: this.expected.origin.lat, lng: this.expected.origin.lng };
    console.log(routeMap)
  }

  generateLetterPoint(index: number): string {
    let result = '';
    while (index > 0) {
      index--;
      result = String.fromCharCode(65 + (index % 26)) + result;
      index = Math.floor(index / 26);
    }
    return result;
  }
  
  openHeaderFilter(): void {
    this.isDivVisible = !this.isDivVisible;
  }

  toggleView(view: 'map' | 'table'): void {
    this.isMapVisible = view === 'map';
  }

  toggleOverlay(): void {
    this.overlayVisible = !this.overlayVisible;
  }

  openMaps(): void {
    const { origin, stops, destination } = this.routeData?.routeMap?.expected || {};
    if (!origin || !destination) return console.warn('Origem ou destino da rota não estão definidos.');
    const waypoints = stops?.map(stop => `${stop.lat},${stop.lng}`).join('|') || '';
    const mapUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&travelmode=driving${waypoints ? `&waypoints=${waypoints}` : ''}`;
    window.open(mapUrl, '_blank');
  }

  private formatDate(date: Date): string {
    return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
  }

  createRouteToa(): void {
    const { routeId } = this.activeFilters;
    const expectedRoute = this.routeData?.routeMap?.expected;
    if (!routeId || !expectedRoute) return this.showSnackBar('Filtros ou dados da rota estão incompletos.', true);

    const selectedRoute = this.filters.routes?.find(route => route.routeId === routeId);
    const family = this.filters.families?.[0];
    if (!selectedRoute) return this.showSnackBar('Rota selecionada não encontrada. Por favor, selecione novamente.', true);
    if (!family) return this.showSnackBar('Família não está definida. Por favor, selecione novamente.', true);

    const waypoints = expectedRoute.stops.map(stop => `${stop.lat},${stop.lng}`).join('|');
    const mapLink = `https://www.google.com/maps/dir/?api=1&origin=${expectedRoute.origin.lat},${expectedRoute.origin.lng}&destination=${expectedRoute.destination.lat},${expectedRoute.destination.lng}&travelmode=driving${waypoints ? `&waypoints=${waypoints}` : ''}`;

    const payload = { routeId, family, mapLink, date: this.formatDate(new Date()) };
    this.rotaService.createVandalismRoute(payload).subscribe({
      next: () => this.showSnackBar('Rota enviada para o TOA com sucesso!', false),
      error: () => this.showSnackBar('Salvamento indisponível para esta região!', true)
    });
  }

  showSnackBar(message: string, isError: boolean): void {
    this.snackBar.open(message, 'Fechar', { duration: 5000, horizontalPosition: 'right', verticalPosition: 'top', panelClass: isError ? 'failure-bar-container' : 'success-bar-container' });
  }

  private handleError(err: any): void {
    console.error('Erro:', err);
    this.loading = false;
    this.error = true;
    this.submit = true;
  }
}