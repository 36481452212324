import { icons } from "src/app/utils/icons";
import { SmartTableCard } from "../types/SmartTableCard";

export class SmartTableDataProcessing {

  static processCards(cards: any): SmartTableCard[] {
    return cards.map((card) => ({
      description: card.description,
      value: card.value,
      icon: icons[card.icon],
      tooltip: card.tooltip
    }));
  }
}