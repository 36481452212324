import { SmartTableCell } from "src/app/components/smart-table/types/SmartTableCell";

export class FieldConfigurator {
  static createField(column: string, key: string, field: string, options: Partial<SmartTableCell> = {}): SmartTableCell {
    return {
      column,
      key,
      field,
      defaultValue: '',
      readonly: false,
      sortable: false,
      clickable: false,
      ...options,
    };
  }
}
