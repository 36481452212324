import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environment';

export interface ILocation {
  lat: number;
  lng: number;
}

export interface Waypoint {
  location: ILocation;
}

export interface Route {
  origin: ILocation;
  destination: ILocation;
  waypoints: Waypoint[];
}

export interface Filters {
  routes: RouteItem[];
  directors: string[];
  states: string[];
  cities: string[];
  families: string[];
}

export interface RouteItem {
  routeId: string;
  routeName: string;
  family: string;
  directors: string;
  states: string;
  cities: string; 
}

export interface RouteMap {
  score: number;
  expected: {
    origin: ILocation;
    destination: ILocation;
    stops: ILocation[];
  };
  realized?: {
    origin: ILocation;
    destination: ILocation;
    stops: ILocation[];
  };
}

export interface RouteTableRow {
  point: number;
  address: string | null;
  hours: string;
}

export interface RouteResponse {
  filters: Filters;
  routeMap: RouteMap;
  routeTable: RouteTableRow[];
}

@Injectable({
  providedIn: 'root'
})
export class RotaService {
  private readonly baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  fetchRoutes(filters: any): Observable<RouteResponse> {
    const params = new HttpParams({
      fromObject: {
        family: filters.family || '',
        directors: filters.directors || '',
        states: filters.states || '',
        cities: filters.cities || '',
        routeId: filters.routeId || '',
      }
    });
  
    return this.httpClient.get<RouteResponse>(`${this.baseUrl}/vandalism/route`, { params });
  }    
  
  createVandalismRoute(input: { routeId: string, family: string, mapLink: string, date: string }): Observable<{ message: string; status: number }> {
    const url = `${this.baseUrl}/vandalism/route/create`;
    return this.httpClient.post<{ message: string; status: number }>(url, input);
  }  
}