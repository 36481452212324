<div class="fullscreen-modal">

  <div *ngIf="loading" class="loading-screen">
    <div class="loading-animation">
      <div class="spinner-container">        
        <div class="spinner"></div>
      </div>
      <p class="loading-title">Carregando...</p>
    </div>
  </div>

  <div *ngIf="!loading" class="container-button-close-map">
    <button class="button-close-map" (click)="closeModal()">      
      <i class="fa fa-compress"></i>
    </button>
  </div>

  <!-- * HEADER FILTER PAGE -->
  <div 
    class="container-header-page full-screen" 
    [class.bg-transition]="isDivVisible" 
    #headerFilter
  >

    <div class="header-img-filter col-1">
      <img
        [src]="isDivVisible ? '../../../../assets/images/claro_logo_70.png' : '../../../../assets/images/claro_logo_50.png'"
        (click)="openHeaderFilter()"
        alt="logo da claro"
      >
      <button *ngIf="isSuggestionTable === true" class="back-sugestion-btn" (click)="backSugestion()">
        <i class="fa fa-chevron-left"></i>
        SUGESTÃO
      </button>
    </div>

    <div 
      class="container-header-filters col-11" 
      [@slideInOut]="isDivVisible ? 'in' : 'out'"
    >
      <div class="container-filters col-12 p-0" style="height: 18vh">
        <app-filter-default
          key="regional"
          filterTitle="regional"
          [icon]="icons.building"
          [dropdownList]="data.value.dropdownList.regional"
          [selectedList]="data.value.selectedList.value.regional"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <app-filter-default
          key="group"
          filterTitle="grupo"
          [icon]="icons.calendar"
          [dropdownList]="data.value.dropdownList.group"
          [selectedList]="data.value.selectedList.value.group"
          (event)="onChange($event)"
        >
        </app-filter-default>
        
        <div class="col-2 col-xxl-2" style="padding: 0.5">
          <app-select-default 
            key="state"
            title="Estados"
            [searchFilter]="true"
            [dropdownList]="data.value.dropdownList.state"
            [selectedItems]="data.value.selectedList.value.state"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
          >
          </app-select-default>

          <app-select-default 
            key="city"
            title="Município"
            [searchFilter]="true"
            [dropdownList]="data.value.dropdownList.city"
            [selectedItems]="data.value.selectedList.value.city"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
          >
          </app-select-default>
        </div>

        <div class="col-2 col-xxl-2" style="padding: 0.5">
          <app-select-default 
            key="cluster"
            title="Cluster"
            [searchFilter]="true"
            [dropdownList]="data.value.dropdownList.cluster"
            [selectedItems]="data.value.selectedList.value.cluster"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
            >
          </app-select-default>

          <app-select-default 
            key="causeGroup"
            title="Causa"
            [searchFilter]="true"      
            [isSinglePermanent]="true"
            [limit]="3"
            [dropdownList]="data.value.dropdownList.causeGroup"
            [selectedItems]="data.value.selectedList.value.causeGroup"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
          >
          </app-select-default>
        </div>
        
        <div class="col-2 col-xxl-2" style="padding: 0.5">
          <app-select-default 
            key="subcluster"
            title="Subcluster"
            [searchFilter]="true"
            [dropdownList]="data.value.dropdownList.subcluster"
            [selectedItems]="data.value.selectedList.value.subcluster"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
          >
          </app-select-default>

          <div>
            <!-- Linha de cima -->
            <div>
              <button class="btn-action-filter" (click)="onReset()" title="Resetar seleção">
                <fa-icon [icon]="icons.trash"></fa-icon>
              </button>
  
              <button class="btn-action-filter" [disabled]="undoDisabled.value" (click)="onUndo()" title="Voltar seleção">
                <fa-icon [icon]="icons.undo"></fa-icon>
              </button>
  
              <button class="btn-action-filter" (click)="onVandalism()" title="Filtrar por vandalismo">
                <fa-icon [icon]="icons.exclamationTriangle"></fa-icon>
              </button>

              <button class="btn-action-filter" (click)="toggleSearchButton()" title="Pesquisar atividade">
                <fa-icon [icon]="icons.search"></fa-icon>
              </button>
            </div>

             <!-- Linha de cima -->
            <div *ngIf="isSearchVisible" class="search-container">
              <input 
                (input)="searchInput.next($event.target.value)"
                type="search"
                class="search-input"
                placeholder="Buscar atividade"
              >
            </div>
          </div>
        </div>
  
        <div class="container-filters-checkbox col-md-3 col-xxl-2">
          <!-- Divisão de checkboxes -->
          <div class="label-checkbox-map d-flex flex-column w-100 mb-3 d-none d-xxl-flex">
            <label class="d-flex align-items-center" style="margin-bottom: 0.5rem;">
              <input 
                type="checkbox" 
                name="peadsActivation" 
                (change)="onActivatePeadsChange($event.target.checked)" 
                [checked]="isPeadsActivated.value"
                class="input-checkbox-map me-2"                
              > PEADS
            </label>

            <label class="label-checkbox-map d-flex align-items-center" style="margin-bottom: 0.5rem;">
              <input 
                type="checkbox" 
                name="accumulatedActivation" 
                (change)="onAccumulatedPeadsChange($event.target.checked)" 
                [checked]="isAccumulatedPeadsActivated.value"
                class="input-checkbox-map me-2"                
              > ACUMULADO
            </label>

            <label class="d-flex align-items-center">
              <input 
                type="checkbox" 
                name="peadsSuggestion" 
                (change)="onSuggestedPeadsChange($event.target.checked)" 
                [checked]="isPeadsSuggestion.value"
                class="input-checkbox-map me-2"                
                > SUGESTÃO
            </label>
          </div>

          <!-- Divisão de contadores -->
          <div class="d-flex flex-column justify-content-between w-100 mb-3 d-none d-xxl-flex">
            <div class="bg-white p-3 rounded shadow-sm" style="font-size: 0.8rem; font-weight: bold; text-align: center;">
              N° de Atividades: <span style="font-weight: normal;  flex-wrap: nowrap;">{{ activitiesNumber }}</span>
            </div>
            <div class="bg-white p-3 rounded shadow-sm" style="font-size: 0.8rem; font-weight: bold; text-align: center;">
              N° de PEADs: <span style="font-weight: normal; flex-wrap: nowrap;">{{ peadsNumber }}</span>
            </div>
          </div>

          <div  *ngIf="!loading" class="d-xxl-none col-12">
            <ngx-slick-carousel
              class="slick-slider slick-dotted"
              #slickModal="slick-carousel"
              [config]="slideConfig"
            >
              <div ngxSlickItem>
                <div class="bg-white p-3 rounded shadow-sm" style="font-size: 0.8rem; font-weight: bold; text-align: center;">
                  N° de Atividades: <span style="font-weight: normal;">{{ activitiesNumber }}</span>
                </div>
              </div>
              <div ngxSlickItem>
                <div class="bg-white p-3 rounded shadow-sm" style="font-size: 0.8rem; font-weight: bold; text-align: center;">
                  N° de PEADs: <span style="font-weight: normal;">{{ peadsNumber }}</span>
                </div>
              </div>
            </ngx-slick-carousel>            
            <div class="d-flex justify-content-between w-100 mb-3">
              <label class="label-checkbox-map d-flex align-items-center">
                <input 
                  type="checkbox" 
                  name="peadsActivation" 
                  (change)="onActivatePeadsChange($event.target.checked)" 
                  [checked]="isPeadsActivated.value"
                  class="input-checkbox-map me-2"                
                > PEADS
              </label>

              <label class="label-checkbox-map d-flex align-items-center">
                <input 
                  type="checkbox" 
                  name="accumulatedActivation" 
                  (change)="onAccumulatedPeadsChange($event.target.checked)" 
                  [checked]="isAccumulatedPeadsActivated.value"
                  class="input-checkbox-map me-2"                
                > ACUMULADO
              </label>

              <label class="label-checkbox-map d-flex align-items-center">
                <input 
                  type="checkbox" 
                  name="peadsSuggestion" 
                  (change)="onSuggestedPeadsChange($event.target.checked)" 
                  [checked]="isPeadsSuggestion.value"
                  class="input-checkbox-map me-2"                
                  > SUGESTÃO
              </label>
          </div>
          </div>
        </div>
      </div>
      <!-- *CONTAINER TOP HEADER -->

      <div class="container-filters col-12 p-0" style="height: 16vh">
        <app-filter-default 
          key="year"
          [icon]="icons.calendar"
          [filterTitle]="'ano'"
          [dropdownList]="data.value.dropdownList.year"
          [selectedList]="data.value.selectedList.value.year"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <app-filter-default
          key="month"
          [filterTitle]="'mês'"
          [icon]="icons.calendar"
          [dropdownList]="data.value.dropdownList.month"
          [selectedList]="data.value.selectedList.value.month"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <app-filter-default
          key="day"
          filterTitle="dia"
          [icon]="icons.calendar"
          [dropdownList]="data.value.dropdownList.day"
          [selectedList]="data.value.selectedList.value.day"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <app-filter-default 
          key="family"
          [filterTitle]="'família'"
          [icon]="icons.building"
          [dropdownList]="data.value.dropdownList.family"
          [selectedList]="data.value.selectedList.value.family"
          (event)="onChange($event)"
        >
        </app-filter-default>
        
        <app-filter-default 
          key="net"
          [filterTitle]="'rede'"
          [icon]="icons.calendar"
          [dropdownList]="data.value.dropdownList.net"
          [selectedList]="data.value.selectedList.value.net"
          (event)="onChange($event)"
        >
        </app-filter-default>
      </div>
      <!-- *CONTAINER BOTTOM HEADER -->
    </div>
  </div>

  <app-suggestion-table
    [suggestionPeads]="suggestionPeads"
    [isLoading]="isLoadingSuggestionTable"
    [isTable]="isSuggestionTable"
    (peadSuggestion)="onSuggestionRowClick($event)"
  >    
  </app-suggestion-table>

  <app-ticket-preview
    [tickets]="filteredMarkers.value"
    [activeYears]="activeYears"
  >
  </app-ticket-preview>

  <agm-map 
    #agmMap  
    style="width: 100%; height: 97%;"
    [latitude]="mapConfig.center.lat"
    [longitude]="mapConfig.center.lng"
    [zoom]="mapConfig.zoom"
    [maxZoom]="mapConfig.maxZoom"
    [disableDefaultUI]="mapConfig.disableDefaultUI"
    [zoomControl]="mapConfig.zoomControl"
    [streetViewControl]="mapConfig.streetViewControl"
    [fullscreenControl]="mapConfig.fullscreenControl"
    (boundsChange)="updateMarkers($event)"
    (mapReady)="onMapReady($event)"
  >
    <agm-marker 
      *ngFor="let ticket of tickets.value let i = index"
      [latitude]="ticket.lat"
      [longitude]="ticket.lng"
      [label]="ticket.label"
      [optimized]="mapConfig.optimized"
      [iconUrl]="iconUrl(ticket.icon)"
      [title]="treatTooltip(ticket)"
      [zIndex]="1"  
      (markerClick)="openTicketPopUp(ticket)"
    >
    </agm-marker>

    <agm-marker       
      *ngIf="peadSelected.lat && peadSelected.lng"
      [latitude]="peadSelected.lat"
      [longitude]="peadSelected.lng" 
      [optimized]="mapConfig.optimized"
      [label]="peadSelected.suggestionId"
      [title]="suggestionTreatTooltip(peadSelected)"
      [iconUrl]="peadSelected.icon"  
      [zIndex]="2"    
    >
    </agm-marker>

    <agm-polyline *ngFor="let route of peads.value"
      [strokeColor]="isResidencial(route.family) ? '#00FF00' : '#008000'"
      [strokeWeight]="10"
      [clickable]="true"
      [geodesic]="true"
    >
      <agm-polyline-point *ngFor="let point of route.connections"
        [latitude]="point.lat"
        [longitude]="point.lng"
      >
      </agm-polyline-point>
    </agm-polyline>
  </agm-map>

  <div class="fullscreen-modal-footer" style="background-color: white; height: 3%">
    <div class="container-legends">
      <app-legend-item 
        *ngFor="let item of legends | async"
        [legend]="item"
      >
      </app-legend-item>
    </div>
  </div>
</div>
