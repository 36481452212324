<div class="container-ticket-pop-up">
  <div class="popup-header">
    <div class="container-title">
      <div class="popup-title">
        <!-- INCIDENT ID -->
        <h2>Incidente {{ treatLegacyTicket(modalData, 'incidentId') }}</h2>
      </div>
      <div class="popup-btn">
        <button type="button" class="btn-close-default" (click)="closeModal()">
          <span class="fa fa-times-circle"></span>
        </button>
      </div>
    </div>           
    
    <!-- <span *ngIf="effectivenessLine" class="effectiveness-line" [ngClass]="getEffectivenessClass()"></span>      
    <div *ngIf="!effectivenessLine">
    </div>    -->
  </div>

  <div class="popup-body">

    <div *ngIf="loading" class="loading-container">
      <div class="loader">
        <div class="ball-pulse">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>

    <!--  ROWS INFO TICKET -->
    <div  class="container-row-pop-up" *ngIf="displayData.length != 0">
      <div class="rows-pop-up">
        <div *ngFor="let data of displayData">
          <app-ticket-pop-up-row
            [label]="data.label"
            [value]="data.value"
            [effectivenessFlag]="data.label === 'Efetividade do Pead' && effectivenessLine"
            [effectivenessClass]="data.label === 'Efetividade do Pead' ? getEffectivenessClass() : ''"
          >
          </app-ticket-pop-up-row>
        </div>
      </div>      
    </div>

    <div class="container-carousel" *ngIf="!loading">
      <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig3">
        <div ngxSlickItem *ngFor="let photo of photos; let i = index">
          <div class="img-popup">
            <img 
              [src]="photo" 
              (click)="openZoomImageModal(photo)" 
              alt="incident"
              class="carousel-image" 
            />
          </div>
        </div>
      </ngx-slick-carousel>
    </div> 
  </div>

  <div class="container-buttons-popup">
    <button
      (click)="generateActivityReportPdf()"
      class="popup-generate-pdf"
      [disabled]="!(this.photos.length === 5)"
    >
      <span *ngIf="!isLoading">Gerar PDF</span>

      <div class="loader-container" *ngIf="isLoading">        
        <div class="spinner-border" role="status"></div>
        <span>Gerando Relatório</span>        
      </div>
    </button>
    
    <div class="incident-report-container" *ngIf="!isButtonLocations">
      <button
        *ngIf="!showInput"
        (click)="toggleOccurrenceInput()"
        class="popup-generate-pdf"
      >
        Enviar boletim de ocorrência      
      </button>      
    
      <div *ngIf="showInput" class="input-container">
        <input 
          [(ngModel)]="occurrenceReport"
          placeholder="Digite o Boletim de ocorrência"
          class="incident-input"
        >
        <button (click)="sendOccurrenceReport()" class="submit-button">
          Enviar
        </button>
      </div>
    </div>

    <button      
      *ngIf="isButtonLocations"
      (click)="openGoogleMaps()"
      class="popup-generate-pdf"
      [disabled]="!(this.photos.length === 5)"
    >
      <span>Vizualizar Localização</span>
    </button>        
  </div>

</div>

<app-image-verification-map
  *ngIf="isMapLoading"
  [locations]="location"
  [imagesUrls]="photos"
  (mapClosed)="onMapClosed()"
>    
</app-image-verification-map>