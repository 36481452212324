<div class="import-modal-container">
  <!-- Cabeçalho -->
  <div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
      &times; <!-- Simbolo de fechar -->
    </button>    
  </div>

  <!-- Corpo -->
  <div class="modal-body">
    <!-- Mensagem de instrução -->
    <p class="instructions">{{ instructions }}</p>

    <!-- Upload do arquivo -->
    <div class="file-upload-container">
      <label for="file" class="file-label">Selecione um arquivo:</label>
      <input id="file" (change)="onFileSelected($event)" type="file" class="file-input" accept=".xlsx, .xls" />
    </div>

    <!-- Resultados da importação -->
    <div class="results" *ngIf="!loading && results">
      <h5 class="result-title">{{ resultTitle }}</h5>
      <ul>
        <li *ngFor="let result of results"><strong>{{ result.label }}:</strong> {{ result.value }}</li>
      </ul>
    </div>

    <!-- Tabela -->
    <div class="table-container" *ngIf="data.value.rows.length">
      <app-smart-table
        [data]="data | async"
        [loading]="loading | async"
        [permissions]="permissions"
        [paginable]="true"
        [preview]="true"
        (onSubmitChanges)="onSubmit($event)"
      >
      </app-smart-table>
    </div>
  </div>

  <!-- Rodapé -->
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary uniform-btn" (click)="downloadTemplate()">Baixar Modelo</button>
    <button type="button" class="btn btn-primary uniform-btn"  [disabled]="(submitDisabled$ | async)" (click)="processFile()">Processar</button>
  </div>
</div>
