import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { IDropdownSettings, MultiSelectComponent, NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

export type Dropdown = string[]

@Component({
  selector: 'app-select-default',
  templateUrl: './select-default.component.html',
  styleUrls: []
})
export class SelectDefaultComponent implements OnInit, OnChanges {

  @ViewChild('dropdown', { static: false }) dropdown: MultiSelectComponent;

  @Input() title: string;
  @Input() key: string;
  @Input() dropdownList = [];
  @Input() selectedItems = [];
  @Input() itemsShowLimit: number;
  @Input() isDisabled: boolean = false;
  @Input() isSingleSelection: boolean = false;
  @Input() isSinglePermanent: boolean = false;
  @Input() limit: number = 0;
  @Input() searchFilter: boolean = false;
  @Output() event = new EventEmitter<string>();
  isDropdownHidden = false;
  dropdownSettings: IDropdownSettings = {};

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit() {
    const defaultDropdownSettings: IDropdownSettings = {     
      selectAllText: 'Todos',
      unSelectAllText: 'Desmarcar todos',
      noDataAvailablePlaceholderText: 'Nenhuma opção disponível',
      searchPlaceholderText: "Procurar",
      singleSelection: this.isSingleSelection,
      enableCheckAll: false,
      showSelectedItemsAtTop: true,
      clearSearchFilter: true,
      allowSearchFilter: this.searchFilter,
      itemsShowLimit: this.itemsShowLimit,
      maxHeight: 150,
    };
  
    // Verificando se um limite de seleção foi especificado
    if (this.limit > 0) {
      // Se sim, criamos uma nova configuração com o limite de seleção
      const dropdownSettingsWithLimit: IDropdownSettings = { limitSelection: this.limit };
      // Combinamos as configurações padrão com a configuração do limite
      this.dropdownSettings = { ...defaultDropdownSettings, ...dropdownSettingsWithLimit };
    } else {
      // Se não, usamos apenas as configurações padrão
      this.dropdownSettings = defaultDropdownSettings;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedItems'] && changes['selectedItems'].currentValue) {  
      // Atualiza o estado interno
      this.selectedItems = [...changes['selectedItems'].currentValue];
  
      // Atualiza o estado visual do dropdown
      if (this.dropdown) {
        this.dropdown.writeValue(this.selectedItems);
        this.dropdown.emittedValue([]);
      }
    }

    if (changes['dropdownList'] && this.dropdownList?.length === 1) {
      this.selectedItems = [...[this.dropdownList[0]]];
      if (this.dropdown) {
        this.dropdown.writeValue(this.selectedItems);
      }
    }
  }
  
  // Listener para o evento de rolagem
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.closeDropdown();
  }

  closeDropdown() {
    const dropdown = this.el.nativeElement.querySelector('.dropdown-list');
    const activeSelect = this.el.nativeElement.querySelector('.dropdown-multiselect--active');

    if (dropdown && activeSelect) {
      this.renderer.setAttribute(dropdown, 'hidden', "");
      this.renderer.removeClass(activeSelect, 'dropdown-multiselect--active');
      this.dropdown.isDropdownOpen = false;
      this.dropdown.onDropDownClose.emit();
    }
  }

  onChange() {
    this.event.emit(`${this.key}:${this.selectedItems.join(',')}`);  
  }
}
