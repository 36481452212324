<div>
  <!-- * HEADER FILTER PAGE -->
  <div class="container-header-page" [class.bg-transition]="isDivVisible" #headerFilter>
    <div class="header-img-filter col-1">
      <img
        [src]="isDivVisible ? '../../../../assets/images/claro_logo_70.png' : '../../../../assets/images/claro_logo_50.png'"
        (click)="openHeaderFilter()" alt="logo da claro">
    </div>

    <div class="container-header-filters col-10" [@slideInOut]="isDivVisible ? 'in' : 'out'">
      <!-- * TOP FILTER -->
      <div class="container-filters col-12 p-0">

        <div class="col-lg-4 col-md-4">
          <app-data-picker-default [dates]="getRangeDate()" (event)="onChange($event)" [backgroundColor]="'#f9f9f9'"
            [color]="'#333333'">
          </app-data-picker-default>
        </div>

        <div class="col-2">
          <app-select-default key="type" title="TIPO" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.types" (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-5">
          <app-filter-default key="director" filterTitle="DIRETORIA" [icon]="icons.calendar"
            [dropdownList]="getButtonsFilterDefault(dropdownFilters.value?.directories)" (event)="onChange($event)">
          </app-filter-default>
        </div>
      </div>
    </div> <!-- TOP FILTER -->
  </div>

  <div class="container">
    <!-- Contêiner da Tabela -->
    <div class="table-container">
      <!-- Estado de erro -->
      <div *ngIf="error.value" class="error-container">
        <p class="error-message">Ocorreu um erro ao carregar os dados.</p>
        <button class="retry-button" (click)="retryFetchData()">Tentar novamente</button>
      </div>

      <!-- Tabela -->
      <app-smart-table *ngIf="!error.value" [data]="data | async" [loading]="loading | async"
        [paginable]="true" [searchable]="true" [sortable]="true" [submitable]="true" [preview]="false"
        [importable]="(recurrenceTableSettings | async).importable"
        [exportable]="(recurrenceTableSettings | async).exportable" [permissions]="userPermissions"
        [currentPage]="currentPage | async" [totalPages]="totalPages | async" [itemsPerPage]="itemsPerPage"
        [importDataCallback]="openImportDataModal" [exportDataCallback]="exportData"
        (onPageChange)="handlePageChange($event)" (onSubmitChanges)="handleChanges($event)">
      </app-smart-table>
    </div>
  </div>
</div>