export enum Permission {
  ADMIN=0,
  ADMIN_SUPPORT=7,
  VANDALISMO=1,
  BATERIA=2,
  BATERIA_GERAL=201,
  ANALITICO=3,
  IMAGE=4,
  IMAGE_VERIFICATION=401,
  OPERATION=5,  
  INVENTORY=6,
  RECURRENCE_VIEW=8,
  RECURRENCE_UPDATE=9,
  RECURRENCE_UPDATE_ADMIN=10,
  RECURRENCE_EXPORT=14,
  DATA_CLASSIFICATION=12,
  GLOBAL_VIEW=13,
  PREVENTIVA=2,
  CRITICAL_ROUTE=14
}

export const stringToPermission = (perm: string): number => {
  const formattedPerm = perm.toUpperCase();
  return Permission[formattedPerm as keyof typeof Permission];
}

const options = [
  { name: 'Visualização do módulo de vandalismo', value: 1, shortName: 'Módulo de Vandalismo', display: true },
  { name: 'Visualização da página de análitico', value: 3, shortName: 'Página de Análitico', display: true },
  { name: 'Visualização do módulo de imagem', value: 4, shortName: 'Módulo de Imagem', display: true },
  { name: 'Visualização da página de suporte administração', value: 4, shortName: 'Página de suporte administração', display: false },
  { name: 'Visualização da página de recorrência', value: 8, shortName: 'Página de Recorrência', display: true },
  { name: 'Atualizar colunas técnicos tela de recorrência', value: 9, shortName: 'Atualização Recorrência (Técnico)', display: true },
  { name: 'Atualizar colunas de administradores tela de recorrência', value: 10, shortName: 'Atualização Recorrência (Administrador)', display: true  }
]

export const permissionToString = (permValue: number): string => {
  const matchedKey = options.find(option => option.value === permValue)?.shortName;

  return matchedKey ? matchedKey : '';
}

export const getPermissionOptions = () => {
  return options.filter((option) => option.display);
}