import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { environment } from 'src/environment';

@Injectable({
  providedIn: 'root'
})
export class IpAddressService {

  private readonly STORAGE_KEY = 'ip_address';
  private readonly ENCRYPTION_KEY = environment.encryptionKey;
  private baseUrl: string;

  private ipAddressSubject: BehaviorSubject<string | null>;
  public ipAddress$: Observable<string | null>;

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.baseUrl = 'https://api.ipify.org?format=json';
  }

  public initIpAddress() {
    const storedIp = this.loadIpAddress();
    this.ipAddressSubject = new BehaviorSubject<string | null>(storedIp);
    this.ipAddress$ = this.ipAddressSubject.asObservable();
    this.fetchIpAddress();
  }

  /**
   * Faz a requisição para obter o endereço IP do usuário
   * e armazena o resultado em memória e no localStorage.
   */
  private fetchIpAddress(): void {
    const hostname = window.location.hostname;
    
    // Verificar se o hostname é localhost ou 127.0.0.1
    if (hostname === 'localhost' || hostname === '127.0.0.1') {
      return;  // Não faz a requisição
    }
  
    // Caso não seja localhost, faz a requisição
    this.http.get<{ ip: string }>(this.baseUrl).subscribe((data) => {
      const ipAddress = data.ip;
      this.ipAddressSubject.next(ipAddress);
      this.saveIpAddress(ipAddress);
    });
  }

  /**
   * Salva o endereço IP no localStorage de forma criptografada.
   * 
   * @param ipAddress O endereço IP a ser salvo.
   */
  private saveIpAddress(ipAddress: string): void {
    this.storageService.saveEncrypted(this.STORAGE_KEY, ipAddress, this.ENCRYPTION_KEY);
  }

  /**
   * Carrega o endereço IP armazenado no localStorage, se houver.
   * 
   * @returns O endereço IP armazenado ou null se não estiver disponível.
   */
  private loadIpAddress(): string | null {
    return this.storageService.loadEncrypted(this.STORAGE_KEY, this.ENCRYPTION_KEY);
  }

  /**
   * Obtém o endereço IP atual da memória.
   * 
   * @returns O endereço IP atual ou null se não estiver disponível.
   */
  public getStoredIpAddress(): { ipAddress: string } | null {
    return { ipAddress: this.ipAddressSubject.value };
  }
}
