import { Component, HostBinding } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { ThemeOptions } from '../../../theme-options';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme.service';
import { environment } from 'src/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {

  faEllipsisV = faEllipsisV;

  constructor(
    public globals: ThemeOptions,
    private router: Router,
    private themeService: ThemeService
  ) {
  }

  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;

  @select('config') public config$: Observable<any>;

  isProduction() {
    return !environment?.production ?? false;
  }

  redirectHome() {
    this.router.navigate(['/home']);
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  toggleHeaderMobile() {
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
  }

  getBackgroundStyle() {
    return `no-repeat url(${this.themeService.getLogo()})`;
  }
}
