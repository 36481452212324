import { Component, Input, OnInit } from '@angular/core';
import { IUser, permissionToString } from 'src/app/interfaces';
import { UpdateUserModalComponent } from '../update-user-modal/update-user-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';
import { BehaviorSubject } from 'rxjs';
import { icons } from 'src/app/utils/icons';
import { DeletePopUpModalComponent } from '../delete-pop-up-modal/delete-pop-up-modal.component';

@Component({
  selector: 'app-users-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent implements OnInit {

  title: string = 'Listagem de usuários';
  @Input() data: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>([]);
  @Input() filteredData: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>([]);
  @Input() loading: boolean = true;
  @Input() error: boolean = false;
  @Input() fetchData: VoidFunction;

  searchTerm: string = '';
  icons=icons;

  constructor(
    private modalService: NgbModal,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.fetchData();
  }

  applyFilter() {
    if (!this.searchTerm) {
      this.filteredData.next(this.data.value);
    } else {
      this.filteredData.next(this.data.value.filter((d) =>
        d.fullName.toLowerCase().includes(this.searchTerm.toLowerCase())
      ));
    }
  }

  ngOnDestroy(): void {
    this.data.unsubscribe();
    this.filteredData.unsubscribe();
  }

  treatPermission(permNumber: number[]): string[] {
    const mappedPermissions = permNumber.map(permissionToString);
    return mappedPermissions.filter((option) => option).map(item => {
      return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
    });
  }

  onDelete(userId: number) {
    const modalRef = this.modalService.open(DeletePopUpModalComponent, { windowClass: 'test' });
    modalRef.componentInstance.userId = userId;
    modalRef.result.then(
      (result) => {
        if (result === 'deleted') {
          this.fetchData();
        }
      }
    );
  }

  onEdit(userId: number) {
    const user = this.userService.getById({ id: userId });
    const modalRef = this.modalService.open(UpdateUserModalComponent, { size: 'md' });
    modalRef.componentInstance.user = user;
    modalRef.result.then(
      (result) => {
        if (result === 'updated') {
          this.fetchData();
        }
      }
    );
  }
}
