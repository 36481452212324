import { SmartTableRow } from "src/app/components/smart-table/types/SmartTableRow";
import { ResponseValidator, Validators } from "../../components/smart-table/utils/Validators";
import { SmartTableCell } from "src/app/components/smart-table/types/SmartTableCell";
import { Parses } from "src/app/components/smart-table/utils/Parses";
import { SmartTableHeader } from "src/app/components/smart-table/types/SmartTableHeader";

export interface TransformStrategyResult {
  headers: SmartTableHeader[],
  rows: SmartTableRow[]
}

export interface TransformStrategy {
  validators(): Record<string, Array<(value: any) => ResponseValidator>>
  transform(data: any[]): TransformStrategyResult
}

export class TransformStrategyImpl implements TransformStrategy {

  private readonly fieldConfig: SmartTableCell[] = [];
  private readonly keyId: string;

  private headers: SmartTableHeader[] = []; // Cache do header para ser reutilizado

  constructor({ keyId, fieldConfig }: { keyId: string, fieldConfig: SmartTableCell[] }) {
    this.fieldConfig = fieldConfig;
    this.keyId = keyId;

    // Calcula o header uma vez durante a inicialização
    this.headers = this.calculateHeaders();
  }

  /**
   * Transforma os dados em linhas da tabela, reutilizando o header calculado.
   * @param data Dados brutos para serem transformados.
   * @returns O resultado com header e rows.
   */
  transform(data: any[]): TransformStrategyResult {
    const rows: SmartTableRow[] = data.reduce((result: SmartTableRow[], row, rowIndex) => {
      const id = row[this.keyId];

      if (!id || !Validators.notEmpty(id)) {
        return result;
      }

      const values = this.fieldConfig.map((field, colIndex) => ({
        cellId: rowIndex * 100 + colIndex + 1,
        column: field.column,
        field: field.field,
        key: field.key,
        value: TransformStrategyImpl.transformFieldValue(row[field.column], field, field.transform),
        editable: field.editable,
        indicator: field.indicator,
        readonly: field.readonly,
        sortable: field.sortable,
        clickable: field.clickable,
        transform: field.transform,
        validators: field.validators
      }));

      result.push({ id, values });

      return result;
    }, []);

    return { headers: this.headers, rows };
  }

  /**
   * Calcula o header com base na configuração de campos.
   * @returns O array de headers.
   */
  private calculateHeaders(): SmartTableHeader[] {
    return this.fieldConfig.map(({ field, column }) => ({
      field,
      column,
      isHighlighted: false,
      sortable: false
    }));
  }

  /**
   * Transforma o valor de um campo baseado na configuração.
   * @param value O valor bruto do campo.
   * @param field A configuração do campo.
   * @returns O valor transformado.
   */
  // Transforma o valor de um campo com base na configuração
  static transformFieldValue(value: any, field: any, parse: string): any {
    if (field.transform) {
      return Parses.parseValue(parse, value);
    }
    return value ?? field.defaultValue;
  }

  // Método para obter validadores
  validators() {
    return this.fieldConfig.reduce((acc, field) => {
      acc[field.column] = field.validators || [];
      return acc;//
    }, {});
  }
}