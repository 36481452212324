<div class="container">
  <div class="support-layout">
    <div class="image-display">
      <div *ngIf="isLoading; else imageBlock">
        <div class="loading-indicator">
          <div class="spinner"></div>
        </div>
      </div>
      <ng-template #imageBlock>
        <div class="container-carousel" *ngIf="!loading">
          <ngx-slick-carousel class="slick-slider" [config]="slideConfig3" (afterChange)="onSlideChange($event)">
            <div ngxSlickItem *ngFor="let photo of photos; let i = index">
              <div class="img-popup">
                <img [src]="photo" (click)="selectImage(photo)" alt="Imagem {{i+1}}" class="carousel-image" />
              </div>
            </div>
          </ngx-slick-carousel>
          <div class="carousel-indicators">
            <span *ngFor="let photo of photos; let i = index"
              [class.active]="i === currentSlide"
              (click)="selectImageFromIndicator(i)"></span>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="sidebar">
      <!-- Título -->
      <h5 *ngIf="!showHistoric">Tipos de Incidente</h5>
      <h5 *ngIf="showHistoric">Histórico de Tickets</h5>
      
      <div *ngIf="!showHistoric" class="ticket"> 
        <strong>Ticket:</strong>
        <p>{{ ticketId }}</p>
      </div>

      <!-- Tipos de Incidente -->
      <div *ngIf="!showHistoric" class="incident-type">
        <label>
          <input type="radio" name="incidentType" value="Furto" [(ngModel)]="selectedIncidentType"
            (change)="selectIncidentType('Furto')" />
          <strong class="type">Furto</strong>
          <span class="info-icon" title="Furto: Roubo de bens sem violência ou ameaça.">?</span>
        </label>
        <label>
          <input type="radio" name="incidentType" value="Sabotagem" [(ngModel)]="selectedIncidentType"
            (change)="selectIncidentType('Sabotagem')" />
          <strong class="type">Sabotagem</strong>
          <span class="info-icon" title="Sabotagem: Ato intencional de danificar ou prejudicar o sistema.">?</span>
        </label>
        <label>
          <input type="radio" name="incidentType" value="Vandalismo" [(ngModel)]="selectedIncidentType"
            (change)="selectIncidentType('Vandalismo')" />
          <strong class="type">Vandalismo</strong>
          <span class="info-icon" title="Vandalismo: Destruição ou dano proposital a propriedades.">?</span>
        </label>
        <label>
          <input type="radio" name="incidentType" value="Inconclusivo" [(ngModel)]="selectedIncidentType"
            (change)="selectIncidentType('Inconclusivo')" />
          <strong class="type">Inconclusivo </strong>
          <span class="info-icon" title="Inconclusivo: Caso em que não há informações suficientes para determinar o tipo de incidente.">?</span>
        </label>
      </div>
    
      <!-- Histórico de Tickets -->
      <div *ngIf="showHistoric" class="historic-card">
        <div *ngFor="let ticket of historicTickets" class="ticket-row">
          <p>Ticket ID: {{ ticket.referenceId }}</p>
          <button (click)="selectTicket(ticket.referenceId)">Selecionar</button>
        </div>
      </div>
    
      <!-- Botões -->
      <button *ngIf="!showHistoric" (click)="updateTicket()" class="update-button">
        <i class="fa fa-paper-plane"></i> Enviar
      </button>
    
      <button *ngIf="!showHistoric" (click)="openHistoric()" class="historic-button">
        <i class="fa fa-table"></i> Histórico
      </button>
    
      <button *ngIf="showHistoric" (click)="closeHistoric()" class="historic-button">
        <i class="fa fa-arrow-left"></i> Voltar
      </button>
    </div>
    
  </div>
</div>