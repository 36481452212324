import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environment';

@Injectable({
  providedIn: 'root'
})
export class DataClassificationService {

  constructor(private http: HttpClient) { }

  /**
   * Obtém o próximo ticket baseado no último ticketId fornecido.
   * @param lastTicketId O ID do último ticket processado.
   * @returns Observable com o próximo ticket (ticketId e guid).
   */
  getNextTicket(lastTicketId: number): Observable<{ ticketId: number; guid: string }> {
    const endpoint = `${environment.baseUrl}/vandalism/image/classifications/next-ticket?lastTicketId=${lastTicketId}`;
    return this.http.get<{ ticketId: number; guid: string }>(endpoint);
  }

  updateClassification(endpoint: string, payload: any): Observable<any> {
    return this.http.post(endpoint, payload);
  }

  getHistoricTickets(endpoint: string): Observable<any[]> {
    return this.http.get<any[]>(endpoint);
  }

  getTicketClassification(ticketId: number): Observable<any> {
    const url = `${environment.baseUrl}/vandalism/image/classifications/${ticketId}`;
    return this.http.get<any>(url);
  }
  
}
