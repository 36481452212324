import { Injectable } from '@angular/core';
import { Permission, TransformStrategyImpl } from 'src/app/interfaces';
import { FieldConfigurator } from 'src/app/interfaces/strategy/fieldConfigurator';

@Injectable()
export class RecurrenceTransformStrategy extends TransformStrategyImpl {

  constructor() {
    super({
      keyId: 'DESIGNAÇÃO',
      fieldConfig: [
        FieldConfigurator.createField('TIPO', 'TIPO', 'type', {
          sortable: true,
          editable: true
        }),
        FieldConfigurator.createField('MÊS REF', 'MÊS REF', 'referenceDate', {
          validators: ['IS_VALID_DATE'],
          transform: 'PARSE_DATE',
          sortable: true,
          editable: true
        }),
        FieldConfigurator.createField('DIRETORIA', 'DIRETORIA', 'director', {
          validators: ['IS_IN_OPTION'],
          options: ['RS', 'CO', 'NO', 'BA/SE', 'SPC', 'SPI', 'MG', 'NE', 'PR', 'RJ/ES', 'SC'],
          editable: true
        }),
        FieldConfigurator.createField('DESIGNAÇÃO', 'DESIGNAÇÃO', 'designation', {
          validators: ['NOT_EMPTY'],
          readonly: true, // Não requer permissão porque é somente leitura
          editable: false,
        }),
        FieldConfigurator.createField('VOL', 'VOL', 'volume', {
          validators: ['IS_NUMBER'],
          readonly: true, // Não requer permissão porque é somente leitura
          editable: true
        }),
        FieldConfigurator.createField('FATO', 'FATO', 'incident', {
          editable: true
        }),
        FieldConfigurator.createField('CAUSA', 'CAUSA', 'cause', {
          editable: true
        }),
        FieldConfigurator.createField('AÇÃO', 'AÇÃO', 'action', {
          editable: true
        }),
        FieldConfigurator.createField('PRAZO', 'PRAZO PARA CONCLUSÃO DO PLANO', 'deadline', {
          editable: true
        }),
      ]
    });
  }
}
