import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from "rxjs";
import { ImageAnalytical } from "src/app/interfaces/dtos/ImageAnalytical";
import { legends } from "src/app/interfaces/dtos/ImageValidation";
import { mountRequestResult, RequestResult } from "src/app/interfaces/RequestContext";
import { AuthService } from "src/app/services/auth.service";
import { PublisherService, TechnicianReportType } from "src/app/services/publisher.service";

export interface MultiSelect {
  description: string,
  value: any
}

@Component({
  selector: 'app-export-users-modal',
  templateUrl: './report-images-modal.component.html',
  styleUrls: ['./report-images-modal.component.scss']
})
export class ReportImagesModalComponent implements OnInit {

  @Input() ticketsAll: BehaviorSubject<Array<{ company: string, technicianId: string }>>;
  @Input() selectedCompanies: MultiSelect[] = [];
  @Input() selectedTechnicianIds: MultiSelect[] = [];
  @Input() selectedLimit: number = 15;
  @Input() activeFilters: ImageAnalytical.Params;
  @Input() type: TechnicianReportType = 'worst';
  @Input() loading: BehaviorSubject<boolean>;

  companyOptions: MultiSelect[] = [];
  technicianOptions: MultiSelect[] = [];
  optionsFiles: MultiSelect[] =[{ description: 'PDF', value: 'PDF' }];
  // TODO: As validações não podem estar fixas no front.
  optionsLegends = [ "GPS", "Luminosidade", "Buffer", "Data", "Dimensões", "Duplicado", "Metadados","Pixels", "Nitidez", "Tamanho", "Atividade inexistente"].map((value => ({ description: value, value })));

  optionsTechnicianCount = [5, 10, 15, 20, 25];

  reportForm: FormGroup;
  
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private publisherService: PublisherService,
    private snackBar: MatSnackBar,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.reportForm = this.fb.group({
      companies: [this.selectedCompanies.map(({ value }) => value), Validators.required],
      technicians: [this.selectedTechnicianIds.map(({ value }) => value), Validators.required],
      validations: [this.optionsLegends.map(({ value }) => value), Validators.required],
      fileTypes: ['PDF', Validators.required],
      technicianCount: [this.optionsTechnicianCount[2], Validators.required],
    });

    this.ticketsAll.subscribe(tickets => {
      if (tickets && tickets.length > 0) {      
        this.companyOptions = this.createSelectOptions(this.ticketsAll.value.map(ticket => ticket.company));
        this.technicianOptions = this.createSelectOptions(this.ticketsAll.value.map(ticket => ticket.technicianId));
      }
    });
  }

  // TODO: Essa lógica de filtragem precisa ser passada para o back.
  createSelectOptions(items: string[]): MultiSelect[] {
    const allOption: MultiSelect = { description: 'Selecionar Todos', value: 'all' };
    return [allOption, ...[...new Set(items)].map(item => ({ description: item, value: item }))];
  }

  onTechnicianSelected(selectedTechnicians: MultiSelect[]) {
    if (selectedTechnicians && selectedTechnicians.length > 0) {
      const allSelected = selectedTechnicians.some(tech => tech.value === 'all');
  
      if (allSelected) {
        const allTechnicians = this.technicianOptions.map(tech => tech.value).filter(value => value !== 'all');
        this.reportForm.patchValue({ technicians: allTechnicians });
  
        const allCompaniesForTechnicians = this.ticketsAll.value
          .filter(ticket => allTechnicians.includes(ticket.technicianId))
          .map(ticket => ticket.company);
  
        const uniqueCompanies = Array.from(new Set(allCompaniesForTechnicians));
        this.reportForm.patchValue({ companies: uniqueCompanies });
      } else {
        const lastSelectedTechnician = selectedTechnicians[selectedTechnicians.length - 1];
        const correspondingCompany = this.findCompanyByTechnician(lastSelectedTechnician.value);
  
        if (correspondingCompany) {
          const selectedCompanies = this.reportForm.value.companies || [];
          const updatedCompanies = [...selectedCompanies, correspondingCompany.value]
            .filter((company, index, self) => self.indexOf(company) === index);
  
          this.reportForm.patchValue({ companies: updatedCompanies });
        }
      }
    }
  }  

  onCompanySelected(selectedCompanies: MultiSelect[]) {
    if (selectedCompanies && selectedCompanies.length > 0) {
      const allSelected = selectedCompanies.some(company => company.value === 'all');
  
      if (allSelected) {
        const allCompanies = this.companyOptions.map(company => company.value).filter(value => value !== 'all');
        this.reportForm.patchValue({ companies: allCompanies });
    
        let techniciansForAllCompanies: string[] = [];
        allCompanies.forEach(company => {
          const techniciansForCompany = this.findTechniciansByCompany(company);
          techniciansForAllCompanies = [...techniciansForAllCompanies, ...techniciansForCompany];
        });
    
        this.technicianOptions = this.createSelectOptions(Array.from(new Set(techniciansForAllCompanies)));
        
        const currentTechnicians = this.reportForm.value.technicians;
        this.reportForm.patchValue({ technicians: currentTechnicians });
      } else {
        let techniciansForAllCompanies: string[] = [];
  
        selectedCompanies.forEach(company => {
          const techniciansForCompany = this.findTechniciansByCompany(company.value);
          techniciansForAllCompanies = [...techniciansForAllCompanies, ...techniciansForCompany];
        });
  
        techniciansForAllCompanies = Array.from(new Set(techniciansForAllCompanies));
  
        this.technicianOptions = this.createSelectOptions(techniciansForAllCompanies);
  
        const filteredTechnicians = this.reportForm.value.technicians
          .filter((tech) => techniciansForAllCompanies.includes(tech));
  
        this.reportForm.patchValue({ technicians: filteredTechnicians });
      }
    } else {
      this.technicianOptions = this.createSelectOptions(this.ticketsAll.value.map(ticket => ticket.technicianId));
      this.reportForm.patchValue({ technicians: [] });
    }
  }
   
  findCompanyByTechnician(technician: string): MultiSelect {
    const ticket =  this.ticketsAll.value.find(ticket => ticket.technicianId === technician);
    return ticket ? { description: ticket.company, value: ticket.company } : null;
  }

  findTechniciansByCompany(company: string): string[] {
    return this.ticketsAll.value
      .filter(ticket => ticket.company === company)
      .map(ticket => ticket.technicianId);
  }
  
  isTypeValidation(): boolean {
    return this.type === 'validation';
  }
  
  get validations(): FormArray {
    return this.reportForm.get('validations') as FormArray;
  }

  openPopUpResponse(requestResult: RequestResult) {
    const closeButtonMessage = 'Fechar';
    const snackBarSettings: MatSnackBarConfig = {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [requestResult.styleClass]
    }
    this.snackBar.open(requestResult.message, closeButtonMessage, snackBarSettings);
    this.activeModal.close();
  }

  getSelectedValidations() {
    const selectedValues = this.reportForm.value.validations
      .map((checked, index) => checked ? this.optionsLegends[index].value : null)
      .filter(value => value !== null)
      .join(",");

    if (selectedValues.size === 0) {
      return legends.join(',');
    }

    return selectedValues
  }

  sendTechnicianReportEmail() {
    this.isLoading = true;

    if (this.reportForm.valid) {
      const { email, fullName } = this.authService.getCurrentUser();
      const { startDate, endDate, regionals, directors, families } = this.activeFilters;

      const payload = {   
        email: email,
        fullname: fullName,
        companies: this.reportForm.value.companies.join(','), 
        technicianIds: this.reportForm.value.technicians.join(','), 
        startDate, 
        endDate, 
        validations: this.getSelectedValidations(), 
        regionals, 
        directors,
        families,
        limit: this.selectedLimit
      }

      this.publisherService
        .sendTechnicianReport(payload, this.type).subscribe(
          (response) => {
            this.isLoading = false;
            const requestResult: RequestResult = mountRequestResult({
              request: { ...response, status: 200 },
              message: response.message,
              expectedStatus: 200
            });
            this.openPopUpResponse(requestResult);
          },
          (error) => {
            this.isLoading = false;
            const requestResult: RequestResult = mountRequestResult({
              request: { ...error },
              message: error.error.message,
              expectedStatus: 200
            });
            this.openPopUpResponse(requestResult);
        });
    }
  }
}
