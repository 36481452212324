import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, delay, delayWhen, retry, retryWhen, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { defaultActiveFilters } from '../interfaces';
import { ActivityInventory } from '../interfaces/models/IInventory';

interface ActivityResponse { headers: { 'Access-Control-Allow-Methods' }, body: '' };

export interface ResourcePosition {
  totalResults?: number,
  time?: number,
  activityId: number,
  activityStatus: string,
  lng: number,
  lat: number,
  s?: string;
}

export interface ActivityFilters {
  family: string,
  state: string,  
}

interface FilterButton {
  show: string,
  value: string
}

export interface DropdownFilters {
  family: FilterButton[],
  net: FilterButton[],
  states: FilterButton[]
}

export const family = [
  { show: 'empresarial', value: 'EMPRESARIAL' },
  { show: 'residencial', value: 'RESIDENCIAL' },
]

export const states = [
  { show: 'AC', value: 'AC' },
  { show: 'AL', value: 'AL' },
  { show: 'AP', value: 'AP' },
  { show: 'AM', value: 'AM' },
  { show: 'BA', value: 'BA' },
  { show: 'CE', value: 'CE' },
  { show: 'DF', value: 'DF' },
  { show: 'ES', value: 'ES' },
  { show: 'GO', value: 'GO' },
  { show: 'MA', value: 'MA' },
  { show: 'MT', value: 'MT' },
  { show: 'MS', value: 'MS' },
  { show: 'MG', value: 'MG' },
  { show: 'PA', value: 'PA' },
  { show: 'PB', value: 'PB' },
  { show: 'PR', value: 'PR' },
  { show: 'PE', value: 'PE' },
  { show: 'PI', value: 'PI' },
  { show: 'RJ', value: 'RJ' },
  { show: 'RN', value: 'RN' },
  { show: 'RS', value: 'RS' },
  { show: 'RO', value: 'RO' },
  { show: 'RR', value: 'RR' },
  { show: 'SC', value: 'SC' },
  { show: 'SP', value: 'SP' },
  { show: 'SE', value: 'SE' },
  { show: 'TO', value: 'TO' }
];

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  private readonly baseUrl: string;  
  private readonly baseUrlImage: string;

  private inventory: BehaviorSubject<ActivityInventory[]> = new BehaviorSubject([]);
  public ActivitiesInventory: BehaviorSubject<ActivityInventory[]> = new BehaviorSubject([]);
  isLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private loadingError: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // public filteredInventory: BehaviorSubject<any> = new BehaviorSubject([]);  
  
  constructor(private httpClient: HttpClient) {  
    this.baseUrl = "https://www.virtsel-fidere-api-homol.com.br:8671"  
    // this.baseUrl = "http://localhost:8671"  
    this.baseUrlImage = "https://images.virtsel-fidere-api.com.br/Preventiva"      
    this.loadActivitiesInventory().subscribe();
  }

  loadActivitiesInventory(): Observable<ActivityInventory[]> {
    return this.httpClient.get<ActivityInventory[]>(this.baseUrl + "/api/inventory/inventarios")
      .pipe(
        tap((data) => this.handleInventoryData(data)),
        retryWhen(errors => 
          errors.pipe(
            tap((error) => console.warn('Retrying due to:', error)),
            delayWhen(() => timer(30000)) // Atraso de 2 segundos antes de cada nova tentativa
          )
        ),
        catchError((error) => {
          console.error('Error loading inventory after retries:', error);
          return of([]); // Fallback em caso de erro após todas as tentativas
        })
      );
  }

  private handleInventoryData(data: ActivityInventory[]): void {
    this.inventory.next(data);
    this.isLoaded.next(true); 
    this.loadingError.next(false);
    this.filterActivitiesInventory(data);
  }

  extractLatLng(geocode: string): { lat: number, lng: number } | null {
    const regex = /lat:(-?\d+\.\d+),lng:(-?\d+\.\d+)/;
    const match = geocode.match(regex);
    if (match) {
      return { lat: parseFloat(match[1]), lng: parseFloat(match[2]) };
    }
    return null;
  }  

  getImageUrlsByActivityId(activityId: number, inventory: ActivityInventory[]): Observable<string[]> {
    const noImage = "/assets/maps/svg-mapa-inventario/no-image.jpeg";
    const activity = inventory.find(item => item.activityId === activityId);
  
    if (!activity) {
      return of([noImage, noImage]);
    }
  
    const imageUrls = [
      activity.xirEvidenciaId?.imageMetadata?.url,
      activity.xirPhoto2Id?.imageMetadata?.url,
      activity.xirPhoto3Id?.imageMetadata?.url,
      activity.xirPhoto4Id?.imageMetadata?.url,
      activity.xirPhoto5Id?.imageMetadata?.url,
      activity.xirPhoto6Id?.imageMetadata?.url,
    ]
    .filter(url => url)
    .map(url => `${this.baseUrlImage}${url}`);
  
    return of(imageUrls.length > 0 ? imageUrls : [noImage, noImage])
      .pipe(delay(1000));
  }

  filterActivitiesInventory(data: ActivityInventory[]): void {
    const activityMap: { [key: number]: ActivityInventory } = {};
    const duplicatedActivities: ActivityInventory[] = [];

    data.forEach(item => {
      const activityId = item.activityId;
      if (!activityMap[activityId]) {
        activityMap[activityId] = { ...item };
      } else {
        duplicatedActivities.push(item);
      }
    });

    this.ActivitiesInventory.next(Object.values(activityMap));
  }

  getInventory(activityId: number): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/api/inventory/atividades/${activityId}`)
      .pipe(
        tap((response) => console.log('Fetched related objects:', response)),
        catchError((error) => this.handleError('Error fetching related objects', error, []))
      );
  }

  searchInventory(idActivities: string): ActivityInventory[] | null {
    const activitiesFilteredIndex = this.inventory.value.findIndex(act => String(act.activityId) === String(idActivities));
    
    if (activitiesFilteredIndex !== -1) {
      const activitiesFiltered = [this.inventory.value[activitiesFilteredIndex]];
      this.ActivitiesInventory.next(activitiesFiltered); // Atualiza o BehaviorSubject ActivitiesInventory
      return activitiesFiltered;
    } else {      
      return null;
    }
  }
  

  private handleError<T>(message: string, error: any, result: T): Observable<T> {
    console.error(message, error);
    return of(result);
  }
}
