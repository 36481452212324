<div class="container-home-page">  

  <div class="container-left-home">
    <div *ngIf="firstLogin === false" class="welcome-container">
      <h1>fidere</h1>
      <h2>seja Bem-Vindo</h2>
      <h3>de volta</h3>
    </div>
    <div *ngIf="firstLogin === true" class="welcome-container">
      <h1>Primeira vez aqui?</h1>
      <h2>seja Bem-Vindo</h2>
      <h3>ao Fidere</h3>
    </div>
  </div> <!-- container-left-hom -->

  <div class="container-suporte" (click)="openTicket()">
    <div class="suporte-icon">
      <img [src]="iconSuport" alt="">
    </div>
    <div class="suporte-content">
      <span class="suporte-content-span-1">Reportar algum problema?</span>
      <span class="suporte-content-span-2">abra uma chamado</span>
    </div>
  </div>

  <div class="container-right-home"> 

    <div class="right-home-user">
      <div class="right-home-user-info">
        <span class="email-home">{{ email }}</span>
        <div class="user-info-role">
          <span>{{ role }}</span>
          <span>{{ fullname }}</span>
        </div>
      </div>
      <img class="home-img-user" [src]="imgUser" alt="">
    </div> 

    <!-- <div class="conatiner-suporte-tickets">
      <div class="suporte-tickets-types-buttons">
        <span class="tickets-types-button">Abertos</span>
        <span class="tickets-types-button">Fechados</span>
      </div>
      <div class="suporte-tickets">        
        <div class="suporte-ticket">      
        </div>
      </div>
    </div>   -->
  </div> <!-- container-right-hom -->
</div>

